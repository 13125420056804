export const userManagement_LABELS = {
  ribbonUserManagement: `{brandName} User Management`,
  userManagement: "User Management",
  userApproval: "User Approval",
  addUser: "Add User",
  addNpis: "Add NPIs",
  firstName: "First Name",
  lastName: "Last Name",
  name: "Name",
  emailAddress: "Email Address",
  contact: "Contact",
  contactNumber: "Contact Number",
  address: "Address",
  organizationAddress: "Organization Address",
  userRole: "User Role",
  status: "Status",
  npi: "NPI",
  organization: "Organization",
  searchOrganization: "Search Organization",
  organizationName: "Organization Name",
  requestRaisedOn: "Request Raised On",
  actions: "Actions",
  superAdmin: "Super User",
  audit: "Audit",
  organizationDetails: "Organization Details",
  linkTo: "Link to",
  addNote: "Add Note",
  noteDescription: "Add reason for rejection (Max of 1024 characters)",
};
