import "./UserManagement.scss";
import { ReactComponent as AddUser } from "../../../src/assets/svg/add_user.svg";
import { userManagement_LABELS } from "../../constants/labels/userManagement/userManagement";
import { useEffect, useRef, useState } from "react";
import UserGrid from "./UserGridView/UserGrid";
import GridHeader from "../shared/GridHeader/GridHeader";
import Button from "@mui/material/Button";
import Slider from "../shared/Widget/Slider/Slider";
import AddUserHandler from "./AddUserHandler/AddUserHandler";
import { UserService } from "../../services/UserService";
import { FORM_TYPE } from "../../enums/formTypes";
import { Roles } from "../../constants/data/Roles";
import { useLazyGetOrganizationByOrganizationIdQuery } from "../../services/rtkQueryServices/OrganizationApi";
import OrganizationHandler from "./OrganizationHandler/OrganizationHandler";
import ConfirmationDialog from "../shared/ConfirmationDialog/ConfirmationDialog";
import { confirmationMessages } from "../../constants/confirmationMessages";
import { FormikProps } from "formik";
import { UserRequest } from "../../models/UserManagement/userRequestModel";
import { USERTAB } from "../../enums/userTabs";
import { ConfigurationService } from "../../services/ConfigurationService";

function UserManagement() {
  const [active, setActive] = useState<any>(
    UserService.userActiveTab || USERTAB.USERMANAGEMENTTAB
  );
  const [openSlider, setOpenSlider] = useState<boolean>(false);
  const [isAllUserDetailsFetched, setIsAllUserDetailsFetched] =
    useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any>(null);
  const setSearchQuery = (values: any) => {
    setSearchResults(values);
  };
  const userFormRef = useRef<FormikProps<UserRequest>>(null);
  const organizationFormRef = useRef<FormikProps<any>>(null);
  const [openConfirmationPopUp, setOpenConfirmationPopUp] =
    useState<boolean>(false);
  const [subHeading, setSubHeading] = useState<string>();
  const [isOpenedFromOrganization, setIsOpenedFromOrganization] =
    useState<boolean>(false);
  const [defaultRole, setDefaultRole] = useState<string>();
  const [setOrganizationData, organizationData] =
    useLazyGetOrganizationByOrganizationIdQuery();
  const [organizationDetailsFromGrid, setOrganizationDetailsFromGrid] =
    useState();
  const [openOrganizationSlider, setOpenOrganizationSlider] =
    useState<boolean>(false);
  useEffect(() => {
    if (UserService.getClaims?.role?.toUpperCase() === Roles.practiceManager) {
      setOrganizationData(UserService?.getClaims?.organizationId);
    }
  }, []);
  useEffect(() => {
    if (UserService.getClaims?.role?.toUpperCase() === Roles.practiceManager) {
      organizationData &&
        setSubHeading(organizationData?.data?.data?.organizationName);
    }
  }, [organizationData]);
  return (
    <div className="user-management-wrapper h-100">
      <div className="d-flex bg-white rounded-3 tabs-container flex-column h-100">
        <ConfirmationDialog
          open={openConfirmationPopUp}
          message={confirmationMessages.changesWillNotBeSaved}
          onConfirm={() => {
            setOpenConfirmationPopUp(false);
            openSlider && setOpenSlider(false);
            openOrganizationSlider && setOpenOrganizationSlider(false);
          }}
          onCancel={() => {
            setOpenConfirmationPopUp(false);
          }}
        />
        <GridHeader
          canSearch
          headerContent={userManagement_LABELS.ribbonUserManagement.replace(
            "{brandName}",
            ConfigurationService.getBrandName()
          )}
          setSearchQuery={(values: string) => {
            setSearchQuery(values);
          }}
          clearInput={active}
          subHeading={subHeading}
          subHeadingOnclick={() => {
            setOrganizationData(UserService?.getClaims?.organizationId);
            setOpenOrganizationSlider(true);
          }}
          showSearchFilterData={isAllUserDetailsFetched ? searchResults : ""}
          actionButtons={
            <>
              <Button
                variant="contained"
                className={`add-user-button-common ${
                  organizationData?.data?.data?.isOrganizationActive
                    ? "add-user-button"
                    : "disable-button"
                }`}
                endIcon={<AddUser />}
                onClick={() => {
                  setOpenSlider(true);
                  setIsOpenedFromOrganization(false);
                }}
                disabled={
                  UserService.getClaims?.role?.toUpperCase() ===
                    Roles.practiceManager &&
                  !organizationData?.data?.data?.isOrganizationActive
                }
              >
                Add User
              </Button>
              <Slider
                isOpen={openSlider}
                onClose={() => {
                  if (userFormRef.current?.dirty)
                    setOpenConfirmationPopUp(true);
                  else {
                    setOpenSlider(false);
                    setDefaultRole(undefined);
                  }
                }}
                title={"Add User"}
              >
                <AddUserHandler
                  practiceManagerOrganizationDetails={
                    organizationData?.data?.data || organizationDetailsFromGrid
                  }
                  isOpenedFromOrganization={isOpenedFromOrganization}
                  onClose={() => {
                    setDefaultRole(undefined);
                    setOpenSlider(false);
                  }}
                  defaultRole={defaultRole}
                  formRef={userFormRef}
                  formType={FORM_TYPE.ADD}
                />
              </Slider>
            </>
          }
        />
        <div className="mb-4 mt-3">
          <span
            className={`${
              active === USERTAB.USERMANAGEMENTTAB && "active-tab"
            } tab p-3 rounded-2`}
            onClick={() => {
              setActive(USERTAB.USERMANAGEMENTTAB);
              UserService.userActiveTab = USERTAB.USERMANAGEMENTTAB;
            }}
          >
            {userManagement_LABELS.userManagement}
          </span>
          {UserService.getClaims?.role?.toUpperCase() === Roles.supportUser && (
            <span
              className={`${
                active === USERTAB.USERAPPROVALTAB && "active-tab"
              } tab p-3 rounded-2`}
              onClick={() => {
                setActive(USERTAB.USERAPPROVALTAB);
                UserService.userActiveTab = USERTAB.USERAPPROVALTAB;
              }}
            >
              {userManagement_LABELS.userApproval}
            </span>
          )}
        </div>
        <div className="user-grid-wrapper">
          <UserGrid
            activeTabId={active}
            searchQuery={searchResults}
            setSearchResults={setSearchResults}
            openAddUserSlider={openSlider}
            setIsAllUserDetailsFetched={setIsAllUserDetailsFetched}
            setOpenAddUserSlider={setOpenSlider}
            setOrganizationDetailsFromGrid={setOrganizationDetailsFromGrid}
            setIsOpenedFromOrganization={setIsOpenedFromOrganization}
            setDefaultRole={setDefaultRole}
          />
          {UserService.getClaims?.role?.toUpperCase() ===
            Roles.practiceManager && (
            <Slider
              isOpen={openOrganizationSlider}
              onClose={() => {
                if (organizationFormRef.current?.dirty)
                  setOpenConfirmationPopUp(true);
                else setOpenOrganizationSlider(false);
              }}
              title={"Organization Details"}
            >
              <OrganizationHandler
                organizationDetails={organizationData?.data?.data}
                onClose={() => {
                  setOpenOrganizationSlider(false);
                }}
                formRef={organizationFormRef}
                openSlider={() => {
                  setOpenSlider(true);
                }}
                setRole={(roleName: string) => {
                  setDefaultRole(roleName);
                }}
              />
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
}
export default UserManagement;
