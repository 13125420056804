import "./AuthenticationLayout.scss";
import { ReactComponent as BrandingImage } from "../../assets/svg/logo.svg";
import { Outlet } from "react-router-dom";
import { LOGIN_LITERALS } from "../../constants/literals";
import { ConfigurationService } from "../../services/ConfigurationService";

export default function AuthenticationLayout() {
  return (
    <div className="login-container d-flex h-100vh">
      <div className=" d-flex align-items-center justify-content-center left-container">
        <div className="logo">
          <img src={ConfigurationService.getLogoUrl()} />
        </div>
        <div className="d-flex align-items-center justify-content-center login-wrapper flex-column position-relative">
          <Outlet />
          <div className="support">
            <div className="copy-rights-wrapper mt-4">
              <div className="mb-3 copy-rights-content">
                {ConfigurationService.getCopyRightText()}
              </div>
              <div className="privacy-policy-wrapper">
                <span
                  className="link"
                  onClick={() => {
                    window.open(process.env.REACT_APP_RIBBON_TERMS_URL);
                  }}
                >
                  Terms of Use
                </span>{" "}
                |{" "}
                <span
                  className="link"
                  onClick={() => {
                    window.open(process.env.REACT_APP_RIBBON_PRIVACY_URL);
                  }}
                >
                  Privacy Policy
                </span>
                {" | "}
                <a
                  href={`mailto:${ConfigurationService.getSupportEmail()}`}
                  className="link"
                >
                  Support
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right-container d-flex align-items-center justify-content-center p-2">
        <div>
          <BrandingImage className="branding-image" />
        </div>
      </div>
    </div>
  );
}
