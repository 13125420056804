import baseApi from "./baseApiService";
import { API_CONSTANTS } from "../../constants/apiConstants";
export const siteConfigurationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSiteConfigurations: builder.query({
      query: () => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.GET_SITE_CONFIGURATIONS}`,
        method: "GET",
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
      }),
    }),
    getSiteConfigurationInformation: builder.query({
      query: () => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.GET_SITE_CONFIGURATION_INFORMATION}`,
        method: "GET",
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
      }),
    }),
    updateSiteConfigurationInformation: builder.query({
      query: (updateConfig) => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.UPDATE_SITE_CONFIGURATION}`,
        method: "POST",
        body: JSON.stringify(updateConfig),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
  }),
});
export const { useLazyGetSiteConfigurationsQuery, useGetSiteConfigurationInformationQuery, useLazyUpdateSiteConfigurationInformationQuery, useLazyGetSiteConfigurationInformationQuery } = siteConfigurationApi;
