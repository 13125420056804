import React from "react";
import { DateRangePicker } from "rsuite";
import * as dateFns from "date-fns";

function DatePicker(props: any) {
  const DateRangeDefaultData: any = [
    {
      label: "Last 7 Days",
      value: [
        dateFns.startOfDay(dateFns.subDays(new Date(), 7)),
        dateFns.endOfDay(dateFns.subDays(new Date(), 1)),
      ],
    },
    {
      label: "Last 30 Days",
      value: [
        dateFns.startOfDay(dateFns.subDays(new Date(), 30)),
        dateFns.endOfDay(dateFns.subDays(new Date(), 1)),
      ],
    },
    {
      label: "Last 90 Days",
      value: [
        dateFns.startOfDay(dateFns.subDays(new Date(), 90)),
        dateFns.endOfDay(dateFns.subDays(new Date(), 1)),
      ],
    },
  ];
  const { allowedRange } = DateRangePicker;
  const { onChange, dateRange, allowedDateRange } = props;

  return (
    <div>
      <DateRangePicker
        onChange={(value) => {
          onChange && onChange(value);
        }}
        ranges={DateRangeDefaultData}
        value={dateRange}
        placeholder="FROM DATE – TO DATE"
        character=" – "
        showOneCalendar={true}
        shouldDisableDate={
          allowedRange &&
          allowedRange(
            allowedDateRange && allowedDateRange.fromDate && new Date(allowedDateRange.fromDate) || dateFns.startOfDay(dateFns.subDays(new Date(), 90)),
            allowedDateRange && allowedDateRange.toDate && new Date(allowedDateRange.toDate) || dateFns.startOfDay(dateFns.subDays(new Date(), 1))
          )
        }
        limitEndYear={(new Date()).getUTCFullYear()}
      />
    </div>
  );
}

export default DatePicker;
