import React, { useEffect, useRef, useState } from "react";
import { userManagement_LABELS } from "../../../constants/labels/userManagement/userManagement";
import { GridColumnDefs } from "../../../models/Grid/gridColumnDefs";
import { ReactComponent as Approved } from "../../../assets/svg/approve.svg";
import { ReactComponent as Reject } from "../../../assets/svg/reject.svg";
import { ReactComponent as Edit } from "../../../assets/svg/edit.svg";
import { ReactComponent as Reset } from "../../../assets/svg/reset.svg";
import Grid from "../../shared/Grid/Grid";
import "./UserGrid.scss";
import UserApprovalHandler from "../UserApprovalHandler/UserApprovalHandler";
import { useLazyGetAllUsersQuery } from "../../../services/rtkQueryServices/UserApi";
import { UserService } from "../../../services/UserService";
import Slider from "../../shared/Widget/Slider/Slider";
import OrganizationHandler from "../OrganizationHandler/OrganizationHandler";
import { useLazyGetOrganizationByOrganizationIdQuery } from "../../../services/rtkQueryServices/OrganizationApi";
import { useLazyGetAllUsersForApprovalQuery } from "../../../services/rtkQueryServices/UserApprovalApi";
import AddUserHandler from "../AddUserHandler/AddUserHandler";
import { FORM_TYPE } from "../../../enums/formTypes";
import UtcDateTime from "../../shared/UtcDateTime/UtcDateTime";
import { Roles } from "../../../constants/data/Roles";
import ConfirmationDialog from "../../shared/ConfirmationDialog/ConfirmationDialog";
import { confirmationMessages } from "../../../constants/confirmationMessages";
import { FormikProps } from "formik";
import { UserRequest } from "../../../models/UserManagement/userRequestModel";
import { useLazyResetPasswordInitiationQuery } from "../../../services/rtkQueryServices/authenticationService";
import { USERTAB } from "../../../enums/userTabs";

function UserGrid(props: any) {
  const {
    activeTabId,
    openAddUserSlider,
    setOpenAddUserSlider,
    setOrganizationDetailsFromGrid,
    setIsOpenedFromOrganization,
    setIsAllUserDetailsFetched,
    setDefaultRole,
  } = props;
  const [openSlider, setOpenSlider] = useState<boolean>();
  const [modalId, setModalId] = useState<string>();
  const [isRejected, setIsRejected] = useState<boolean>(false);
  const [rejectState, setRejectState] = useState<boolean>(false);
  const [rowData, setRowData] = useState([]);
  const [approvalRowData, setApprovalRowData] = useState([]);
  const [applicationUserId, setApplicationUserId] = useState("");
  const [setOrganizationDetails, organizationDetails] =
    useLazyGetOrganizationByOrganizationIdQuery();
  const [organizationHandlerData, setOrganizationHandlerData] = useState();
  const [rowcount, setRowCount] = useState(0);
  const [approvalRowcount, setApprovalRowCount] = useState(0);
  const [filterCriteria, setFilterCriteria] = useState<any>({
    pageNumber: 1,
    pageSize: 20,
  });
  const [pageSize, setPagesize] = useState<number>(20);
  const [page, setPage] = useState<number>(1);
  const [sliderType, setSliderType] = useState<FORM_TYPE>();
  const [clickedUserDetails, setClickedUserDetails] = useState<any>();
  const [setUsersData, usersData] = useLazyGetAllUsersQuery();
  const [setApprovalDataHandler, approvalDataHandler] =
    useLazyGetAllUsersForApprovalQuery();
  useEffect(() => {
    setRowData([]);
    if (usersData?.isSuccess) {
      usersData && setRowData(usersData?.data?.data?.listOfUsers);
      usersData && setRowCount(usersData?.data?.data?.totalCount);
      if (setIsAllUserDetailsFetched && props?.searchQuery?.length > 0) {
        setIsAllUserDetailsFetched(true);
      } else {
        setIsAllUserDetailsFetched(false);
      }
    }
  }, [usersData]);
  const [resetApplicationId, setResetApplicationId] = useState<number | null>(
    null
  );
  const [resetPassword] = useLazyResetPasswordInitiationQuery();

  useEffect(() => {
    if (activeTabId === USERTAB.USERMANAGEMENTTAB) {
      setUsersData(filterCriteria);
    } else {
      setApprovalDataHandler(filterCriteria);
    }
  }, [filterCriteria, openSlider, openAddUserSlider]);

  useEffect(() => {
    if (approvalDataHandler.isSuccess) {
      approvalDataHandler &&
        setApprovalRowData(approvalDataHandler?.data?.data?.listOfUsers);
      approvalDataHandler &&
        setApprovalRowCount(approvalDataHandler.data?.data.totalCount);
    }
    if (
      approvalDataHandler?.isSuccess &&
      setIsAllUserDetailsFetched &&
      props?.searchQuery?.length > 0
    ) {
      setIsAllUserDetailsFetched(true);
    } else {
      setIsAllUserDetailsFetched(false);
    }
  }, [approvalDataHandler]);

  useEffect(() => {
    if (organizationDetails.isSuccess) {
      setOrganizationHandlerData(organizationDetails?.data?.data);
    } else if (organizationDetails.isError) {
      setOrganizationHandlerData(undefined);
    }
  }, [organizationDetails]);

  useEffect(() => {
    if (
      props?.searchQuery !== null &&
      (props?.searchQuery?.length === 0 || props?.searchQuery?.length > 2)
    ) {
      setPage(1);
      let searchString =
        props?.searchQuery?.trim().length === 0 ||
        props?.searchQuery?.trim().length > 2
          ? props?.searchQuery?.trim() === ""
            ? undefined
            : props?.searchQuery?.trim()
          : null;
      setFilterCriteria({
        ...filterCriteria,
        pageNumber: 1,
        searchString: searchString,
        sortByColumnName: searchString?.length > 0 ? "FirstName" : null,
        sortOrder: searchString?.length > 0 ? "ASC" : null,
      });
    } else if (
      props?.searchQuery?.length > 0 ||
      props?.searchQuery?.length <= 2
    ) {
      setIsAllUserDetailsFetched(true);
    }
  }, [props.searchQuery]);

  useEffect(() => {
    resetFilter();
  }, [activeTabId]);

  const resetFilter = () => {
    setPage(1);
    setFilterCriteria({
      pageNumber: 1,
      pageSize: 20,
    });
    props?.setSearchResults(null);
    setPagesize(20);
  };
  const UserActions = (props: any) => {
    return (
      <div>
        <Edit
          onClick={() => {
            setClickedUserDetails(props?.data);
            setSliderType(FORM_TYPE.EDIT);
            setOpenSlider(true);
          }}
          className="cursor-pointer"
          title={"Edit"}
        />
        <Reset
          onClick={() => {
            if (props.data.isActive)
              setResetApplicationId(props.data.applicationUserId);
          }}
          className={
            "ms-3 cursor-pointer " +
            (!props.data.isActive ? "disable-reset-Password" : "")
          }
          title={props.data.isActive ? "Reset Password" : ""}
        />
        <ConfirmationDialog
          open={resetApplicationId === props.data.applicationUserId}
          message={
            props.data.isAccountVerified
              ? confirmationMessages.resetPasswordWillBeSent.replace(
                  "<email>",
                  props.data.emailAddress
                )
              : confirmationMessages.setPasswordWillBeSent.replace(
                  "<email>",
                  props.data.emailAddress
                )
          }
          onConfirm={() => {
            resetPassword({ email: props.data.emailAddress });
            setResetApplicationId(null);
          }}
          onCancel={() => setResetApplicationId(null)}
        />
      </div>
    );
  };
  const UserApprovalActions = (userApprovalProps: any) => {
    return (
      <div className="d-flex">
        {userApprovalProps?.data?.approvalStatus?.toUpperCase() !==
        "REJECTED" ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="me-3 cursor-pointer">
              <Approved
                title="Approve"
                onClick={() => {
                  setModalId(userApprovalProps?.data?.applicationUserId);
                  setRejectState(false);
                  setIsRejected(false);
                }}
              />
            </div>
            <div className="cursor-pointer">
              <Reject
                title="Reject"
                onClick={() => {
                  setModalId(userApprovalProps?.data?.applicationUserId);
                  setRejectState(true);
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className="rejected-on me-3 cursor-pointer text-overflow-ellipse"
            onClick={() => {
              setModalId(userApprovalProps?.data?.applicationUserId);
              setIsRejected(true);
            }}
            title="Rejected"
          >
            Rejected
          </div>
        )}
        <UserApprovalHandler
          onClose={() => {
            setApprovalDataHandler(filterCriteria);
          }}
          userApprovalProps={userApprovalProps}
          modalId={modalId}
          setModalId={setModalId}
          isRejected={isRejected}
          setIsRejected={setIsRejected}
          rejectState={rejectState}
          setRejectState={setRejectState}
        />
      </div>
    );
  };
  const UserStatus = (props: any) => {
    return (
      <div
        className={
          "text-overflow-ellipse " +
          (props?.data?.isActive ? "user-active" : "user-disabled")
        }
        title={props?.data?.isActive ? "Active" : "Inactive"}
      >
        {props?.data?.isActive ? <>Active</> : <>Inactive</>}
      </div>
    );
  };
  const OrganizationContent = (props: any) => {
    return (
      <div>
        <div
          className="name-wrapper cursor-pointer text-overflow-ellipse"
          onClick={() => {
            if (props?.data?.organizationId)
              setOrganizationDetails(props?.data?.organizationId);
            setApplicationUserId(props?.data?.applicationUserId);
          }}
          title={props?.data?.organizationName}
        >
          {props?.data?.organizationName}
        </div>
        <Slider
          isOpen={applicationUserId === props?.data?.applicationUserId}
          onClose={() => {
            if (organizationFormRef.current?.dirty)
              setOpenConfirmationPopUp(true);
            else {
              setApplicationUserId("");
              setUsersData(filterCriteria);
            }
          }}
          title={"Organization Details"}
        >
          <OrganizationHandler
            organizationDetails={organizationHandlerData}
            onClose={() => {
              setApplicationUserId("");
              setUsersData(filterCriteria);
            }}
            formRef={organizationFormRef}
            openSlider={() => {
              setOpenAddUserSlider(true);
              setOrganizationDetailsFromGrid(organizationHandlerData);
              if (setIsOpenedFromOrganization != undefined)
                setIsOpenedFromOrganization(true);
            }}
            setRole={(roleName: string) => {
              setDefaultRole(roleName);
            }}
          />
        </Slider>
      </div>
    );
  };
  const FirstNameContent = (props: any) => {
    return (
      <div
        className={`${
          props?.data?.isActive
            ? "name-wrapper cursor-pointer"
            : "disabled-user"
        } text-overflow-ellipse`}
        title={props?.data?.firstName}
        onClick={() => {
          setClickedUserDetails(props?.data);
          setSliderType(FORM_TYPE.VIEW);
          setOpenSlider(true);
        }}
      >
        {props?.data?.firstName}
      </div>
    );
  };
  const Npis = (props: any) => {
    const npis = props?.data?.npis;
    return (
      <div title={npis} className="text-overflow-ellipse">
        {npis}
      </div>
    );
  };
  const userManagementColumnDefs: GridColumnDefs[] = [
    {
      headerName: userManagement_LABELS.firstName,
      field: "firstName",
      cellRenderer: FirstNameContent,
      style: { maxWidth: "100px", width: "100px" },
      sortable: true,
    },
    {
      headerName: userManagement_LABELS.lastName,
      field: "lastName",
      style: { maxWidth: "100px", width: "100px" },
      sortable: true,
    },
    {
      headerName: userManagement_LABELS.userRole,
      field: "role",
      style: { maxWidth: "100px", width: "100px" },
      sortable: true,
    },
    {
      headerName: userManagement_LABELS.emailAddress,
      field: "emailAddress",
      style: { maxWidth: "100px", width: "100px" },
      sortable: true,
    },
    {
      headerName: userManagement_LABELS.contact,
      field: "contactNumber",
      sortable: true,
      style: { maxWidth: "100px", width: "100px" },
    },
    {
      headerName: userManagement_LABELS.status,
      field: "isActive",
      cellRenderer: UserStatus,
      hide: UserService.getClaims?.role === Roles.practiceManager,
      style: { maxWidth: "100px", width: "100px" },
      sortable: true,
    },
    {
      headerName: userManagement_LABELS.organization,
      field: "organizationName",
      sortable: true,
      cellRenderer: OrganizationContent,
      hide: UserService.getClaims?.role === Roles.practiceManager,
      alwaysEnabled: true,
      style: { maxWidth: "100px", width: "100px" },
    },
    {
      field: userManagement_LABELS.actions,
      headerName: "Actions",
      cellRenderer: UserActions,
      sortable: false,
      alwaysEnabled: true,
      style: { maxWidth: "100px", width: "100px" },
    },
  ];
  const userApprovalColumnDefs: GridColumnDefs[] = [
    {
      headerName: userManagement_LABELS.firstName,
      field: "firstName",
      style: { maxWidth: "100px", width: "100px" },
      sortable: true,
    },
    {
      headerName: userManagement_LABELS.lastName,
      field: "lastName",
      style: { maxWidth: "100px", width: "100px" },
      sortable: true,
    },
    {
      headerName: userManagement_LABELS.emailAddress,
      field: "emailAddress",
      style: { maxWidth: "100px", width: "100px" },
      sortable: true,
    },
    {
      headerName: userManagement_LABELS.contact,
      field: "contactNumber",
      sortable: true,
      style: { maxWidth: "100px", width: "100px" },
    },
    {
      headerName: userManagement_LABELS.npi,
      field: "npis",
      cellRenderer: Npis,
      style: { maxWidth: "100px", width: "100px" },
      sortable: true,
    },
    {
      headerName: userManagement_LABELS.organization,
      field: "organizationName",
      sortable: true,
      style: { maxWidth: "100px", width: "100px" },
    },
    {
      headerName: userManagement_LABELS.requestRaisedOn,
      field: "requestRaisedDate",
      cellRenderer: UtcDateTime,
      style: { maxWidth: "100px", width: "100px" },
      sortable: true,
    },
    {
      field: userManagement_LABELS.actions,
      headerName: "Actions",
      cellRenderer: UserApprovalActions,
      style: { maxWidth: "100px", width: "100px" },
    },
  ];
  const onPageChange = (value: any) => {
    setPage(value);
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: value,
    });
  };
  const onPageSizeChange = (value: any) => {
    setPagesize(value);
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: 1,
      pageSize: value,
    });
  };
  const sortOrder = (columnName: string, order: string) => {
    if (order === "asc" || order === "desc") {
      setFilterCriteria({
        ...filterCriteria,
        sortByColumnName: columnName,
        sortOrder: order,
      });
    } else {
      setFilterCriteria({
        ...filterCriteria,
        sortByColumnName: null,
        sortOrder: null,
      });
    }
  };
  const [openConfirmationPopUp, setOpenConfirmationPopUp] =
    useState<boolean>(false);
  const userFormRef = useRef<FormikProps<UserRequest>>(null);
  const organizationFormRef = useRef<FormikProps<any>>(null);
  return (
    <div className="h-100">
      <ConfirmationDialog
        open={openConfirmationPopUp}
        message={confirmationMessages.changesWillNotBeSaved}
        onConfirm={() => {
          if (applicationUserId) {
            setApplicationUserId("");
            setUsersData(filterCriteria);
          } else {
            setOpenSlider(false);
          }
          setOpenConfirmationPopUp(false);
        }}
        onCancel={() => {
          setOpenConfirmationPopUp(false);
        }}
      />
      {activeTabId === USERTAB.USERMANAGEMENTTAB ? (
        <Grid
          columnDefs={userManagementColumnDefs}
          rowData={rowData}
          serverSidePagination
          rowCount={rowcount}
          serverSideSorting
          showPagination
          showFilterIcon
          pageSizeList={[10, 20, 50, 100]}
          pageSize={pageSize}
          page={page}
          masterDetail
          headerContent={userManagement_LABELS.ribbonUserManagement}
          showTotalRowCount
          uniqueIdentifierFieldName={"id"}
          sortBy={(columnName, order) => {
            sortOrder(columnName, order);
          }}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          disableRow={(row) => !row?.isActive}
        ></Grid>
      ) : (
        <Grid
          columnDefs={userApprovalColumnDefs}
          rowData={approvalRowData}
          serverSidePagination
          rowCount={approvalRowcount}
          serverSideSorting
          showPagination
          showFilterIcon
          pageSizeList={[10, 20, 50, 100]}
          pageSize={pageSize}
          page={page}
          masterDetail
          headerContent={userManagement_LABELS.ribbonUserManagement}
          showTotalRowCount
          uniqueIdentifierFieldName={"id"}
          sortBy={(columnName, order) => {
            sortOrder(columnName, order);
          }}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        ></Grid>
      )}
      <Slider
        isOpen={openSlider}
        onClose={() => {
          if (userFormRef?.current?.dirty) setOpenConfirmationPopUp(true);
          else {
            setDefaultRole(undefined);
            setOpenSlider(false);
          }
        }}
        title={sliderType === FORM_TYPE.EDIT ? "Edit User" : "View User"}
      >
        <AddUserHandler
          onClose={() => {
            setDefaultRole(undefined);
            setOpenSlider(false);
          }}
          formRef={userFormRef}
          formType={sliderType}
          setFormType={setSliderType}
          initialFormValues={clickedUserDetails}
        />
      </Slider>
    </div>
  );
}

export default UserGrid;
