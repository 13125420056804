import { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import { regex } from "../../constants/regex";
import { ReactComponent as Email } from "../../assets/svg/mail.svg";
import { ReactComponent as Success } from "../../assets/svg/green_tick.svg";
import { Formik, Form } from "formik";
import Input from "../shared/Input/Input";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { UserService } from "../../services/UserService";
import { validationMessages } from "../../constants/forms/validationMessages";
import { LOGIN_LITERALS } from "../../constants/literals";
import {
  useLazySetPasswordQuery,
  useVerifyUserQuery,
} from "../../services/rtkQueryServices/authenticationService";
import LoginError from "../Error/LoginError";
import Login from "./Login";
import { useAuth } from "../../context/AuthProvider";
export default function SetPassword(props: any) {
  const { userId } = useParams();
  const {
    data: user,
    isLoading,
    isError,
    error,
    refetch,
  } = useVerifyUserQuery<any>(
    userId && encodeURIComponent(encodeURIComponent(userId))
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  useEffect(() => {
    refetch();
  }, [userId]);

  const initialFormValue = useMemo<any>(() => {
    return {
      password: "",
      confirmPassword: "",
    };
  }, []);
  const formInitialValues = initialFormValue;
  const [setPassword] = useLazySetPasswordQuery();
  const auth = useAuth();

  const validationSchema = () =>
    Yup.object({
      password: Yup.string()
        .required(validationMessages.enterPassword)
        .min(8, validationMessages.passwordLength)
        .max(64, validationMessages.passwordMaxLength)
        .matches(
          regex.passwordValidation,
          validationMessages.passwordRegexMatch
        ),
      confirmPassword: Yup.string()
        .required(validationMessages.reEnterPassword)
        .oneOf([Yup.ref("password")], validationMessages.confirmPassword),
    });
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState<string>();
  const handleChange = () => {
    setErrorMessage("");
  };
  return (
    <>
      {successMessage ? (
        <div className="align-items-center d-flex">
          <span>
            <Success />
          </span>
          <span>{successMessage}</span>
        </div>
      ) : (
        <>
          {userId && user?.data?.userEmail && !user?.data?.isLinkExpired ? (
            (user?.data?.isAccountVerified && props?.isReset) ||
            (!user?.data?.isAccountVerified && !props?.isReset) ? (
              <Formik
                initialValues={{
                  ...formInitialValues,
                  userEmail: user?.data?.userEmail,
                }}
                validationSchema={validationSchema}
                onSubmit={(values: any) => {
                  values.emailVerificationIdentifier =
                    encodeURIComponent(userId);
                  setPassword(values).then((res) => {
                    let data = res?.data;
                    if (data?.isSuccess) {
                      if (data?.data?.redirectTo) {
                        auth?.logOut(false);
                        setSuccessMessage(props?.isReset ? LOGIN_LITERALS.passwordUpdated : LOGIN_LITERALS.passwordSuccessfullySet);
                      }
                      else {
                        setSuccessMessage(
                          LOGIN_LITERALS.passwordSuccessfullySet
                        );
                      }
                    } else {
                      setErrorMessage(
                        (res?.isError &&
                          res.error &&
                          (res.error as any).data.message) ||
                          LOGIN_LITERALS.somethingWentWrong
                      );
                    }
                  });
                }}
              >
                {(formik) => (
                  <Form
                    onChange={handleChange}
                    onSubmit={formik.handleSubmit}
                    className="login-form-container"
                  >
                    <div className="login-form">
                      <div className="login-header mb-4">
                        {props.isReset ? "Reset Password" : "Set Password"}
                      </div>
                      {props.isReset && (
                        <div className="login-mail mb-2">
                          <span className="me-2">
                            <Email />
                          </span>
                          <span>
                            {user?.data?.userEmail}
                          </span>
                        </div>
                      )}
                      <Input
                        name="password"
                        placeholder="Enter Password"
                        type="password"
                        errorMsg={
                          (formik.touched.password || formik.submitCount > 0) &&
                          formik.errors.password &&
                          (formik.errors as any).password
                        }
                        value={formik.values.password}
                        onInput={formik.handleChange}
                        required
                      />
                      <Input
                        name="confirmPassword"
                        placeholder="Re Enter Password"
                        type="password"
                        errorMsg={
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword &&
                          (formik.errors as any).confirmPassword
                        }
                        required
                        value={formik.values.confirmPassword}
                        onInput={formik.handleChange}
                      />
                    </div>
                    {errorMessage && (
                      <span className="error-message">{errorMessage}</span>
                    )}
                    <div className="login-actions">
                      <Button
                        className={
                          !formik.isValid || !formik.dirty
                            ? "form-button disabled"
                            : "form-button"
                        }
                        type="submit"
                        disabled={!formik.isValid || !formik.dirty}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : user?.data?.isAccountVerified && !props?.isReset ? (
              <Login informationMessage={LOGIN_LITERALS.passwordAlreadySet} />
            ) : !user?.data?.isAccountVerified && props?.isReset ? (
              <LoginError
                errorMessage={LOGIN_LITERALS.accessDenied}
              ></LoginError>
            ) : (
              !isLoading &&
              !user?.data?.userEmail &&
              !error?.data && <>{LOGIN_LITERALS.somethingWentWrong}</>
            )
          ) : (
            <>
              {!isLoading &&
                (isError ? (
                  error?.data ? (
                    <LoginError
                      errorMessage={error?.data?.message}
                    ></LoginError>
                  ) : (
                    <>{LOGIN_LITERALS.somethingWentWrong}</>
                  )
                ) : !user?.data?.isAccountVerified && props?.isReset ? (
                  <LoginError
                    errorMessage={LOGIN_LITERALS.accessDenied}
                  ></LoginError>
                ) : user?.data?.isAccountVerified && !props?.isReset ? (
                  <Login
                    informationMessage={LOGIN_LITERALS.passwordAlreadySet}
                  />
                ) : (
                  <LoginError
                    {...user?.data}
                    isReset={props?.isReset}
                    userId={userId}
                    errorMessage={"Link has expired."}
                  ></LoginError>
                ))}
            </>
          )}
        </>
      )}
    </>
  );
}
