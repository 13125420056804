import { useEffect } from "react";
import { LOGIN_LITERALS } from "../../constants/literals";
import { dataStore } from "../../constants/dataStore";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../constants/routePaths";
import LoginError from "../../components/Error/LoginError";
import { useAuth } from "../../context/AuthProvider";

function SessionExpired() {

    const navigate = useNavigate();
    const auth = useAuth();

    useEffect(() => {
        if (localStorage.getItem(dataStore.access_token) != null && !auth?.isLoggedIn) {
            navigate(routePaths.login);
        }
    }, []);

    return (
        <>
            <LoginError errorMessage={LOGIN_LITERALS.sessionExpired}></LoginError>
        </>
    );
}

export default SessionExpired;
