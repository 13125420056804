import { useState, createContext, useContext, useEffect } from "react";
import { UserService } from "../services/UserService";
import { authProvider } from "../models/authentication/authProvider";
import { routePaths } from "../constants/routePaths";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext<authProvider | null>(null);

export const AuthProvider = (props: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(UserService.isLoggedIn);
  const navigate = useNavigate();

  const logOut = (navigateToLogin: boolean = true) => {
    UserService.logOut(navigateToLogin);
    setIsLoggedIn(false);
  };

  const logIn = (tokenResponse: any) => {
    UserService.logIn(tokenResponse);
    setIsLoggedIn(true);
    navigate(routePaths.base);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, logOut, logIn }}>
      {props?.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
