import { API_CONSTANTS } from "../../constants/apiConstants";
import { UserService } from "../UserService";
import baseApi from "./baseApiService";
export const authenticationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.query({
      query: (loginCredential) => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.LOGIN}`,
        method: "POST",
        body: JSON.stringify(loginCredential),
        headers: {
          "Content-Type": "application/json",
        },
      }),
      keepUnusedDataFor: 0,
    }),
    updatePassword: builder.query({
      query: (updatedPassword) => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.UPDATE_PASSWORD}`,
        method: "PUT",
        body: JSON.stringify(updatedPassword),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    verifyUser: builder.query({
      query: (userId) => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.VERIFY_USER}${userId}`,
        method: "GET",
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
      }),
    }),
    resetPasswordInitiation: builder.query({
      query: (email) => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.RESET_PASSWORD_INITIATION}`,
        method: "PUT",
        body: JSON.stringify(email),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    setPasswordInitiation: builder.query({
      query: (email) => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.SET_PASSWORD_INITIATION}${email}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    addUser: builder.query({
      query: (user) => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.ADD_USER}`,
        method: "POST",
        body: user,
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    getAllRoles: builder.query({
      query: () => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.GET_ALL_ROLES}`,
        method: "GET",
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
      }),
    }),
    updateUser: builder.query({
      query: (user) => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.UPDATE_USER}`,
        method: "POST",
        body: user,
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    validateOtp: builder.query({
      query: (user) => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.VALIDATE_OTP}`,
        method: "POST",
        body: user,
        headers: {
          Authorization: `Bearer ${UserService.loginCode}`,
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    resendOtp: builder.query({
      query: (user) => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.RESEND_OTP}`,
        method: "POST",
        body: user,
        headers: {
          Authorization: `Bearer ${UserService.loginCode}`,
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    changePassword: builder.query({
      query: (updatedPassword) => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.CHANGE_PASSWORD}`,
        method: "PUT",
        body: JSON.stringify(updatedPassword),
        headers: {
          Authorization: `Bearer ${UserService.accessToken}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    forgotPassword: builder.query({
      query: (email) => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.FORGOT_PASSWORD}`,
        method: "PUT",
        body: JSON.stringify(email),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    setPassword: builder.query({
      query: (updateUserPassword) => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.SET_PASSWORD}`,
        method: "PUT",
        body: updateUserPassword,
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    signOut: builder.query({
      query: (userId) => ({
        url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.SIGN_OUT}`,
        method: "POST",
        body: userId,
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
  }),
});

export const {
  useLazyUpdatePasswordQuery,
  useLazyLoginQuery,
  useVerifyUserQuery,
  useLazyResetPasswordInitiationQuery,
  useLazyAddUserQuery,
  useGetAllRolesQuery,
  useLazyUpdateUserQuery,
  useLazyValidateOtpQuery,
  useLazyResendOtpQuery,
  useLazyChangePasswordQuery,
  useLazyForgotPasswordQuery,
  useLazySetPasswordQuery,
  useLazySetPasswordInitiationQuery,
  useLazySignOutQuery,
} = authenticationApi;
