import React, {
    FormEvent,
    MouseEvent,
    KeyboardEvent,
    FocusEvent,
    ReactNode,
    useState,
    useEffect,
    useRef,
  } from "react";
  import "./../input.scss";
  import { Field, FieldInputProps, useField } from "formik";
  import Info from "../../SVG/Info";
  import { ReactComponent as Upload} from "./../../../../assets/svg/Upload.svg";
  import { ReactComponent as Delete} from "./../../../../assets/svg/Delete.svg";
  
  interface InputProps {
    value?: string;
    disabled?: boolean;
    type?: "file";
    label?: string;
    name?: string;
    showHelpTextIcon?: boolean;
    labelHelpText?: string | ReactNode;
    required?: boolean;
    placeholder?: string;
    labelSuffix?: ReactNode;
    errorMsg?: string;
    accept?: string,
    image?: ReactNode;
    fileName?: string | null;
    as?: string;
    infoIconProps?: any,
    readonly?: boolean;
    onClick?: (e: MouseEvent) => void;
    onInput?: (e: FormEvent) => void;
    onClear?: (e: any) => void;
    onChange?: (e: any) => void;
  }
  
  const FileUpload: React.FC<InputProps> = ({
    onClear,
    onChange,
    label,
    required,
    disabled,
    labelSuffix,
    readonly,
    errorMsg,
    labelHelpText,
    accept,
    image,
    fileName,
    ...props
  }) => {
    const [type, setType] = useState("file");
    const inputRef = useRef(null);
    const [field, meta] = useField((props as any));

    return (
      <div className="file-upload input-group">
        {label && (
          <label className="align-items-center d-flex">
            {label}
            {labelSuffix && (
              <span className="ms-2">{labelSuffix && labelSuffix}</span>
            )}
            {required && <span className="required">*</span>}
            {labelHelpText && (
              <span className="help-icon ">
                <Info {...props.infoIconProps}/>
                <div className="help-info">{labelHelpText}</div>
              </span>
            )}
          </label>
        )}
        {/* <div>
          {image}
        </div>         */}
        <div
          className={`file-input-control ${disabled && "input-disabled"} ${
            readonly && "input-readonly "
          } ${errorMsg && "error"}`}
        >
            <input
              {...props}
              type={type}
              ref={inputRef}
              {...field}
              accept={accept}
              onChange={onChange}
              disabled={disabled}
              style={{ display: 'none' }}
              value={props?.value ? props?.value : ""}
            ></input>
          <div onClick={()=> {(inputRef.current as any).click()}} className="upload-button">
            <Upload /> <span>Upload</span>
          </div>
          {fileName && (
            <div className="fileName-section">
              <div>
                {fileName}
              </div>
              <div
                onClick={(e) => {
                  onClear && onClear(e);
                }}
                className={`affix-clear`}
                title="clear"
              >
                <Delete />
              </div>
            </div>
          )}
        </div>
        {errorMsg && (
          <div className="error-message">
            <span className="custom-error-message">{errorMsg}</span>
          </div>
        )}
      </div>
    );
  };
  export default FileUpload;
  