export const metricsInfoTexts = {
    RegisteredUsers: "Users registered from Extension and added in user management.",
    LoggedInUsers: "Count of users who have logged in (based on log-in time) to extension in the given date range.",
    ActiveUsers: "If any activity (log-in, forget password, get patient details, log-out etc.,) performed by the user within the date filter then the user is considered as active.",
    InActiveUsers: "Users who logged-in and did not perform any action in the given date range.",
    NumberOfPresentations: "Tracks the number of patient's data displayed in the extension, should be based on user unique presentations.",
    NumberOfPatientsReviewed: "Tracks the number of patients data displayed in the extension more than once, should be based on user unique presentations.",
    DailyAverageUsers: "Average count of users per day in the selected date range.",
    WeeklyAverageUsers: "Average count of users per week in the selected date range.",
    AllMetricsDownload: "Consolidated data download."
};
  