import { dataStore } from "../constants/dataStore";
import { LOGIN_LITERALS } from "../constants/literals";

export class ConfigurationService {
  static applyConfigs(data: any) {
    if (data.brandName != null) {
      localStorage.setItem(
        dataStore.brand_name,
        data.brandName || LOGIN_LITERALS.welcome
      );
    }
    if (data.primaryColor != null) {
      document.documentElement.style.setProperty(
        "--primary-color",
        data.primaryColor
      );
      localStorage.setItem(
        dataStore.theme_primary_color,
        data.primaryColor || ""
      );
    }
    if (data.secondaryColor != null) {
      document.documentElement.style.setProperty(
        "--secondary-color",
        data.secondaryColor
      );
      localStorage.setItem(
        dataStore.theme_secondary_color,
        data.secondaryColor || ""
      );
    }
    if (data.copyRightText != null) {
      localStorage.setItem(dataStore.copyright, data.copyRightText || "");
    }
    if (data.brandUserManagementLogoUrl != null) {
      localStorage.setItem(
        dataStore.brand_um_logo,
        data.brandUserManagementLogoUrl || ""
      );
    }
    if (data.supportEmailAddress != null) {
      localStorage.setItem(
        dataStore.support_email,
        data.supportEmailAddress || ""
      );
    }
  }

  static getLogoUrl() {
    return (
      localStorage.getItem(dataStore.brand_um_logo) ||
      process.env.REACT_APP_RIBBON_PRODUCT_LOGO_URL ||
      ""
    );
  }

  static getBrandName() {
    return (
      localStorage.getItem(dataStore.brand_name) ||
      process.env.REACT_APP_RIBBON_LOGIN_HEADER ||
      ""
    );
  }

  static getPrimaryColor() {
    return localStorage.getItem(dataStore.theme_primary_color);
  }

  static getSecondaryColor() {
    return localStorage.getItem(dataStore.theme_secondary_color);
  }

  static getCopyRightText() {
    return (
      localStorage.getItem(dataStore.copyright) || LOGIN_LITERALS.copyRights
    );
  }
  static getSupportEmail() {
    return (
      localStorage.getItem(dataStore.support_email) ||
      process.env.REACT_APP_RIBBON_SUPPORT_EMAIL
    );
  }
}
