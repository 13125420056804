export const errorMessages = {
  required: (fieldName: string) => `${fieldName} is required`,
  invalidEmail: "Invalid email address",
  characterLimit: (fieldName: string, limit: number) =>
    `${fieldName} should not exceed ${limit} characters`,
  alphabetsAndSpaceValidation(fieldName: string) {
    return `${fieldName} must only contain alphabets and white spaces`;
  },
  length(fieldName: string, limit: number) {
    return `${fieldName} must contain ${limit} characters`;
  },
  npiLengthError: "NPI must have 10 digits",
  npiRegexMisMatch:
    "Invalid input. Please enter comma separated 10 digit numbers.",
  activeSessionExists:
    "An active session exists already, please try again later.",
};
