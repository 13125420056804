import React, { createContext, useContext } from "react";
import Loader from "../components/shared/Loader/Loader";
import { useSelector } from "react-redux";

const LoaderContext = createContext<any>(null);

export const LoaderProvider = (props: any) => {
  const isAnythingLoading = useSelector((state) => {
    return (
      Object.values((state as any)?.api.queries).some(
        (entry) => (entry as any)?.status === "pending"
      ) &&
      !Object.values((state as any)?.api.queries).some(
        (entry) =>
          (entry as any)?.status === "pending" &&
          (entry as any)?.endpointName === "getOrganizationByName"
      )
    );
  });
  return (
    <LoaderContext.Provider value={isAnythingLoading}>
      <Loader isLoading={isAnythingLoading} />
      {props?.children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => {
  return useContext(LoaderContext);
};
