import "./SiteConfiguration.scss";
import Input from "./../shared/Input/Input";
import { siteConfiguration_LABLES, siteConfiguration_Placeholders } from "./../../constants/labels/siteConfiguration/siteConfiguration"
import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import { Button } from "@mui/material";
import { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import { SiteConfigurationRequest } from "../../models/Request/SiteConfigurationRequest";
import { validationMessages } from "../../constants/forms/validationMessages";
import { regex } from "../../constants/regex";
import { useGetSiteConfigurationInformationQuery, useLazyUpdateSiteConfigurationInformationQuery } from "../../services/rtkQueryServices/SiteConfigurationApi";
import { ReactComponent as Configuration } from "./../../assets/svg/Configuration.svg";
import FileUpload from "../shared/Input/FileUpload/FileUpload";
import { ConfigurationService } from "../../services/ConfigurationService";

const validationSchema = Yup.object().shape({
    brandName: Yup.string()
        .required(validationMessages.isRequired(siteConfiguration_LABLES.brandName))
        .min(3, validationMessages.minlength(siteConfiguration_LABLES.brandName, 3))
        .max(50, validationMessages.maxlength(siteConfiguration_LABLES.brandName, 50)),
    supportEmailAddress: Yup.string()
        .required(validationMessages.isRequired(siteConfiguration_LABLES.supportEmail))
        .max(128, validationMessages.maxlength(siteConfiguration_LABLES.supportEmail, 128))
        .matches(regex.emailValidation, validationMessages.validEmail),    
    fromEmailAddress: Yup.string()
        .required(validationMessages.isRequired(siteConfiguration_LABLES.fromEmail))
        .max(128, validationMessages.maxlength(siteConfiguration_LABLES.fromEmail, 128))
        .matches(regex.emailValidation, validationMessages.validEmail),
    copyRightText: Yup.string()
        .required(validationMessages.isRequired(siteConfiguration_LABLES.copyright))
        .max(250, validationMessages.maxlength(siteConfiguration_LABLES.copyright, 250)),
    primaryColor: Yup.string()
        .required(validationMessages.isRequired(siteConfiguration_LABLES.colorPrimary))
        .max(10, validationMessages.maxlength(siteConfiguration_LABLES.colorPrimary, 10))
        .matches(regex.hexValueColorCode, validationMessages.validColorCode),
    secondaryColor: Yup.string()
        .required(validationMessages.isRequired(siteConfiguration_LABLES.colorSecondary))
        .max(10, validationMessages.maxlength(siteConfiguration_LABLES.colorSecondary, 10))
        .matches(regex.hexValueColorCode, validationMessages.validColorCode),
});

const infoIconProps = { width: "15px", height: "15px", fillColor: "#8998B7" };
const imageUrlToBase64 = async (url: string) => {
    const data = await fetch(url, {method: "GET", mode: "no-cors"});
    const blob = await data.blob();
    return blob;
};

const validateFileUpload = (file: any) => {
    let supportedTypes = (process.env.REACT_APP_RIBBON_LOGO_SUPPORTED_TYPES)?.split(',') || ["image/png"];
    let error = null;
    if(file && supportedTypes.indexOf(file.type) < 0) {
        error=`Upload only ${process.env.REACT_APP_RIBBON_LOGO_SUPPORTED_TYPES} files`;
    } else if(file && file.size >= 30000){
        error="File size must be less than 30KB"
    }
    return error;
}

function SiteConfiguration() {
    const siteConfigurationInfo = useGetSiteConfigurationInformationQuery(null);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);
    const [updateSiteConfig] = useLazyUpdateSiteConfigurationInformationQuery();
    const [errorMessage, setErrorMessage] = useState<any>();
    const [initialImageData, setInitialImageData] = useState<any>();
    const [fileUploadErrors, setFileUploadErrors] = useState<any>({
        brandUserManagementLogo: null,
        brandExtensionLogo: null,
        brandExtensionLogoSmall: null
    });
    const initialFormValues = useMemo<SiteConfigurationRequest>(() => {
        if(siteConfigurationInfo?.data){
            setShowForm(true);
        }
        if(siteConfigurationInfo?.data?.data) {
            setInitialImageData({
                brandUserManagementLogo: {
                    fileData: "", 
                    fileExtension: getFileNameFromUrl(siteConfigurationInfo?.data?.data?.brandUserManagementLogoUrl)?.split(".")[1]
                },
                brandExtensionLogo: {
                    fileData: "", 
                    fileExtension: getFileNameFromUrl(siteConfigurationInfo?.data?.data?.brandExtensionLogoUrl)?.split(".")[1]
                },
                brandExtensionLogoSmall: {
                    fileData: "", 
                    fileExtension: getFileNameFromUrl(siteConfigurationInfo?.data?.data?.brandLogoUrl)?.split(".")[1]
                }
            });
        }
        return { 
            ...siteConfigurationInfo?.data?.data,
        };
    },[siteConfigurationInfo]);
    const formRef = useRef<FormikProps<SiteConfigurationRequest>>(null);

    function getFileNameFromUrl(url: string | object) {
        if(typeof(url) == "object") {
            return url && (url as any).fileName;
        }
        let urlParts = (url && url.split('/')) || null;
        return (urlParts && urlParts[urlParts?.length - 1]) || null;
    }

    useEffect(() => {
        if(fileUploadErrors.brandUserManagementLogo == null && fileUploadErrors.brandExtensionLogo == null && fileUploadErrors.brandExtensionLogoSmall == null){
            setIsFormValid(true);
        }
        else {
            setIsFormValid(false);
        }
    },[fileUploadErrors]);

    return (
        <div className="configuration-section d-flex flex-column h-100 gap-3">
            <div className="configuration-header">
                <Configuration />&nbsp;Brand Configuration
            </div>
            <div className="configuration-body">
                { showForm && <Formik
                    initialValues={initialFormValues}
                    validationSchema={validationSchema}
                    onSubmit={(values: any) => {
                        updateSiteConfig({ 
                                ...initialImageData,
                                ...values, 
                                siteConfigurationId: siteConfigurationInfo?.data?.data?.siteConfigurationId
                        }).then((resp: any) => {
                            setErrorMessage(null);
                            if(resp?.data?.isSuccess) {
                                window.location.href = window.location.href;
                            }
                        }).catch((err: any) => {
                            setErrorMessage(err);
                        })
                    }}
                    innerRef={formRef}
                >
                    {(formik) => (
                        <Form className="d-flex flex-column site-config-form gap-4">
                            <div className="form-body flex-grow-1">
                                <div className="brand-details-config-section gap-5">
                                    <Input
                                        name="brandName"
                                        placeholder={siteConfiguration_Placeholders.brandName}
                                        label={siteConfiguration_LABLES.brandName}
                                        errorMsg={
                                            formik.touched.brandName &&
                                            formik.errors.brandName &&
                                            (formik.errors as any).brandName
                                        }
                                        value={formik.values.brandName}
                                        onInput={(e) => {
                                            formik.handleChange(e);
                                        }}
                                        required
                                    />

                                    <Input
                                        name="supportEmailAddress"
                                        placeholder={siteConfiguration_Placeholders.supportEmail}
                                        label={siteConfiguration_LABLES.supportEmail}
                                        errorMsg={
                                            formik.touched.supportEmailAddress &&
                                            formik.errors.supportEmailAddress &&
                                            (formik.errors as any).supportEmailAddress
                                        }
                                        value={formik.values.supportEmailAddress}
                                        onInput={(e) => {
                                            formik.handleChange(e);
                                        }}
                                        required
                                    />
                                    <Input
                                        name="fromEmailAddress"
                                        placeholder={siteConfiguration_Placeholders.fromEmail}
                                        label={siteConfiguration_LABLES.fromEmail}
                                        errorMsg={
                                            formik.touched.fromEmailAddress &&
                                            formik.errors.fromEmailAddress &&
                                            (formik.errors as any).fromEmailAddress
                                        }
                                        value={formik.values.fromEmailAddress}
                                        onInput={(e) => {
                                            formik.handleChange(e);
                                        }}
                                        required
                                    />
                                </div>
                                <div className="brand-theme-config-section d-flex gap-5">
                                    <Input
                                        name="copyRightText"
                                        placeholder={siteConfiguration_Placeholders.copyright}
                                        label={siteConfiguration_LABLES.copyright}
                                        errorMsg={
                                            formik.touched.copyRightText &&
                                            formik.errors.copyRightText &&
                                            (formik.errors as any).copyRightText
                                        }
                                        value={formik.values.copyRightText}
                                        onInput={(e) => {
                                            formik.handleChange(e);
                                        }}
                                        required
                                    />

                                    <Input
                                        name="primaryColor"
                                        placeholder={siteConfiguration_Placeholders.colorPrimary}
                                        label={siteConfiguration_LABLES.colorPrimary}
                                        errorMsg={
                                            formik.touched.primaryColor &&
                                            formik.errors.primaryColor &&
                                            (formik.errors as any).primaryColor
                                        }
                                        value={formik.values.primaryColor}
                                        onInput={(e) => {
                                            formik.handleChange(e);
                                        }}
                                        labelHelpText="Provide the Hex Code. This shall reflect the basic color of the portal."
                                        required
                                        infoIconProps={infoIconProps}
                                    />
                                </div>
                                <div className="brand-logo-config-section d-flex gap-5">
                                    <FileUpload 
                                        name="userManagementLogo"
                                        placeholder={siteConfiguration_LABLES.userManagementLogoURL}
                                        label={siteConfiguration_LABLES.userManagementLogoURL}
                                        accept={process.env.REACT_APP_RIBBON_LOGO_SUPPORTED_TYPES}
                                        errorMsg={
                                            fileUploadErrors && fileUploadErrors.brandUserManagementLogo
                                        }
                                        fileName={getFileNameFromUrl(formik.values.brandUserManagementLogoUrl)}
                                        onClear={() => {
                                            formik.setFieldValue("brandUserManagementLogoUrl", null);
                                            setFileUploadErrors({...fileUploadErrors, brandUserManagementLogo: validationMessages.isRequired(siteConfiguration_LABLES.userManagementLogoURL)});
                                        }}
                                        onInput={(e) => {
                                            let image = (e.target as any).files[0];
                                            let reader = new FileReader();                               
                                            reader.onload = function () {
                                                let base64String = (reader?.result as string).replace("data:", "")?.replace(/^.+,/, "");
                                                formik.setFieldValue("brandUserManagementLogoUrl", { fileName: image?.name });
                                                formik.setFieldValue("brandUserManagementLogo", { fileData: base64String, fileExtension: image?.name?.split(".")[1]});
                                            }
                                            reader.readAsDataURL(image);
                                            setFileUploadErrors({...fileUploadErrors, brandUserManagementLogo: validateFileUpload(image)});
                                        }}
                                        required
                                        infoIconProps={infoIconProps}
                                        labelHelpText={<p>Supported Version: {(process.env.REACT_APP_RIBBON_LOGO_SUPPORTED_TYPES)?.split(',') || "image/png"} <br/>Max. size limit 30KB</p>}
                                    />
                                    <FileUpload 
                                        name="extensionLogo"
                                        placeholder={siteConfiguration_LABLES.extensionLogoURL}
                                        label={siteConfiguration_LABLES.extensionLogoURL}
                                        accept={process.env.REACT_APP_RIBBON_LOGO_SUPPORTED_TYPES}
                                        errorMsg={
                                            fileUploadErrors && fileUploadErrors.brandExtensionLogo
                                        }
                                        fileName={getFileNameFromUrl(formik.values.brandExtensionLogoUrl)}
                                        onClear={() => {
                                            formik.setFieldValue("brandExtensionLogoUrl", null);
                                            setFileUploadErrors({...fileUploadErrors, brandExtensionLogo: validationMessages.isRequired(siteConfiguration_LABLES.extensionLogoURL)});
                                        }}
                                        onInput={(e) => {
                                            let image = (e.target as any).files[0];
                                            let reader = new FileReader();                               
                                            reader.onload = function () {
                                                let base64String = (reader?.result as string).replace("data:", "")?.replace(/^.+,/, "");
                                                formik.setFieldValue("brandExtensionLogoUrl", { fileName: image?.name });
                                                formik.setFieldValue("brandExtensionLogo", { fileData: base64String, fileExtension: image?.name?.split(".")[1], fileName: image?.name });
                                            }
                                            reader.readAsDataURL(image);
                                            setFileUploadErrors({...fileUploadErrors, brandExtensionLogo: validateFileUpload(image)});
                                        }}
                                        required
                                        infoIconProps={infoIconProps}
                                        labelHelpText={<p>Supported Version: {(process.env.REACT_APP_RIBBON_LOGO_SUPPORTED_TYPES)?.split(',') || "image/png"} <br/>Max. size limit 30KB</p>}
                                    />
                                    <FileUpload 
                                        name="brandLogo"
                                        placeholder={siteConfiguration_LABLES.extensionLogoSmallURL}
                                        label={siteConfiguration_LABLES.extensionLogoSmallURL}
                                        accept={process.env.REACT_APP_RIBBON_LOGO_SUPPORTED_TYPES}
                                        errorMsg={
                                            fileUploadErrors && fileUploadErrors.brandExtensionLogoSmall
                                        }
                                        fileName={getFileNameFromUrl(formik.values.brandLogoUrl)}
                                        onClear={(e) => {
                                            formik.setFieldValue("brandExtensionLogoSmall", null);
                                            setFileUploadErrors({...fileUploadErrors, brandExtensionLogoSmall: validationMessages.isRequired(siteConfiguration_LABLES.extensionLogoSmallURL)});
                                        }}
                                        onInput={(e) => {
                                            let image = (e.target as any).files[0];
                                            let reader = new FileReader();                               
                                            reader.onload = function () {
                                                let base64String = (reader?.result as string).replace("data:", "")?.replace(/^.+,/, "");
                                                formik.setFieldValue("brandLogoUrl", { fileName: image?.name });
                                                formik.setFieldValue("brandExtensionLogoSmall", { fileData: base64String, fileExtension: image?.name?.split(".")[1], fileName: image?.name });
                                            }
                                            reader.readAsDataURL(image);
                                            setFileUploadErrors({...fileUploadErrors, brandExtensionLogoSmall: validateFileUpload(image)});
                                        }}
                                        required
                                        infoIconProps={infoIconProps}
                                        labelHelpText={<p>Supported Version: {(process.env.REACT_APP_RIBBON_LOGO_SUPPORTED_TYPES)?.split(',') || "image/png"} <br/>Max. size limit 30KB</p>}
                                    />
                                </div>
                                {errorMessage && (
                                    <>
                                    <div className="login-error-message p-4 mt-4">
                                        <span className="error-message">{errorMessage} </span>
                                    </div>
                                    </>
                                )}
                            </div>
                            <div className="form-footer d-flex justify-content-end gap-3">
                                <Button
                                    className="form-button clear-all"
                                    onClick={() => {
                                        formik.resetForm();
                                        setFileUploadErrors({
                                            brandExtenstionLogoSmall: null,
                                            brandExtensionLogo: null,
                                            brandUserManagementLogo: null
                                        });
                                    }}
                                >
                                    Clear All
                                </Button>
                                <Button
                                    className={"form-button submit"}
                                    type="submit"
                                    disabled={!formik.isValid || !formik.dirty || !isFormValid}
                                >
                                    Save
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>}
            </div>
        </div>
    );
}

export default SiteConfiguration;