export const DashboardMetricTypeEnums = {
  registeredUsers: 1,
  loggedInUsers: 2,
  activeUser: 3,
  inactiveUsers: 4,
  patientPresentations: 5,
  patientReviewed:  6,
  dailyAverageUsers: 7,
  weeklyAverageUsers: 8
};
