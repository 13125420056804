import React, { useEffect, useMemo, useState } from "react";
import "./Dashboard.scss";
import { DashboardMetricTypeEnums } from "../../constants/data/DashboardMetricTypeEnums";
import { ReactComponent as RegisteredUsers } from "../../../src/assets/svg/Registered users.svg";
import { ReactComponent as LoggedInUsers } from "../../../src/assets/svg/Logged In Users.svg";
import { ReactComponent as ActiveUser } from "../../../src/assets/svg/Active Users.svg";
import { ReactComponent as Presentations } from "../../../src/assets/svg/Presentations.svg";
import { ReactComponent as InactiveUsers } from "../../../src/assets/svg/Inactive Users.svg";
import Info from "../shared/SVG/Info";
import MultiSelectDropdown from "../shared/MultiSelectDropdown/MultiSelectDropdown";
import DatePicker from "../shared/DatePicker";
import { Button } from "@mui/material";
import { useGetAllRolesQuery } from "../../services/rtkQueryServices/authenticationService";
import { Roles } from "../../constants/data/Roles";
import { useGetAllOrganizationNamesQuery as useGetAllOrganizationNamesQuery } from "../../services/rtkQueryServices/OrganizationApi";
import {
  useGetDateFilterRangeQuery,
  useLazyGetDashboardMetricsQuery,
  useLazyDownloadExcelForDashboardMetricQuery,
  useLazyDownloadAllMetricsExcelForDashboardMetricQuery,
} from "../../services/rtkQueryServices/DashboardApi";
import { metricsInfoTexts } from "../../constants/metricsInfoTexts";
import * as dateFns from "date-fns";
import Download from "../shared/SVG/Download";
import { Utility } from "../../utils/Utility";
import { UserService } from "../../services/UserService";
import { USERTAB } from "../../enums/userTabs";

function Dashboard() {
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [filterCriteria, setFilterCriteria] = useState<any>({
    fromDate: Utility.getCurrentUTCDate(),
    toDate: Utility.getCurrentUTCDate()
  });
  const [setMetricsFilters, metricsData] = useLazyGetDashboardMetricsQuery();
  const [downloadExcel] = useLazyDownloadExcelForDashboardMetricQuery();
  const [downloadAllMetricsExcel] = useLazyDownloadAllMetricsExcelForDashboardMetricQuery();

  useEffect(() => {
    UserService.userActiveTab = USERTAB.USERMANAGEMENTTAB;
    setMetricsFilters(filterCriteria);
  }, []);
  useEffect(() => {
    if (metricsData.isSuccess) {
      setDashboardData(metricsData.data && metricsData.data.data?.slice().sort((a: any, b: any) => a.dashboardMetricType > b.dashboardMetricType ? 1:-1));
    }
  }, [metricsData]);
  function RenderDashboardCardIcon(metricType: any) {
    return (
      <>
        {DashboardMetricTypeEnums.registeredUsers === metricType.metricType ? (
          <RegisteredUsers />
        ) : DashboardMetricTypeEnums.loggedInUsers === metricType.metricType ? (
          <LoggedInUsers />
        ) : DashboardMetricTypeEnums.activeUser === metricType.metricType ? (
          <ActiveUser />
        ) : DashboardMetricTypeEnums.patientPresentations === metricType.metricType ? (
          <Presentations />
        ) : DashboardMetricTypeEnums.inactiveUsers === metricType.metricType ? (
          <InactiveUsers />
        ) : (
          <ActiveUser />
        )}
      </>
    );
  }
  function RenderDashboardCardInfoText(metricType: any, metricName: any) {
    return (
      <>
        {DashboardMetricTypeEnums.registeredUsers === metricType.metricType ? (
          metricsInfoTexts.RegisteredUsers
        ) : DashboardMetricTypeEnums.loggedInUsers === metricType.metricType ? (
          metricsInfoTexts.LoggedInUsers
        ) : DashboardMetricTypeEnums.activeUser === metricType.metricType ? (
          metricsInfoTexts.ActiveUsers
        ) : DashboardMetricTypeEnums.inactiveUsers === metricType.metricType ? (
          metricsInfoTexts.InActiveUsers
        ) : DashboardMetricTypeEnums.patientPresentations === metricType.metricType ? (
          metricsInfoTexts.NumberOfPresentations
        ) : DashboardMetricTypeEnums.patientReviewed === metricType.metricType ? (
          metricsInfoTexts.NumberOfPatientsReviewed
        ) : DashboardMetricTypeEnums.dailyAverageUsers === metricType.metricType ? (
          metricsInfoTexts.DailyAverageUsers
        ) : DashboardMetricTypeEnums.weeklyAverageUsers === metricType.metricType ? (
          metricsInfoTexts.WeeklyAverageUsers
        ) : metricName.metricName}
      </>
    );
  }
  const [organizationList, setOrganizationList] = useState<string[]>([]);
  const [title, setTitle] = useState<string>();
  const [dateRange, setDateRange] = useState<any>();
  const [dateFilterRangeDaysCount, setDateFilterRangeDaysCount] = useState<Number>(0);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [isDownloadAllMetricEnabled, setIsDownloadAllMetricEnabled] = useState<boolean>(true);
  const roles = useGetAllRolesQuery(null);
  const rolesList = useMemo(() => {
    let rolesData = roles?.data?.data;
    return rolesData?.filter(
      (item: any) => item?.roleName?.toUpperCase() !== Roles.supportUser
    );
  }, [roles]);

  const setHoverText = (dropDownMenuList: any, selectedValue: string, valuePropertyName: string, displayPropertyName: string)=>
  {
    let hoverText = "";
    for (let i = 0; i<selectedValue.length; i++)
    {
      const displayValue = dropDownMenuList.find(
      (obj: any) => obj[valuePropertyName] === selectedValue[i]);
      if (displayValue != undefined) 
      { 
        hoverText = hoverText + ", " + displayValue[displayPropertyName];
      };
    }
    setTitle(hoverText.slice(1));
  }

  const organizationData = useGetAllOrganizationNamesQuery(null);
  const dateFilterRange = useGetDateFilterRangeQuery(null);
  const allowedDateRange = useMemo(() => {
    setDateFilterRangeDaysCount(dateFns.differenceInDays(new Date(dateFilterRange?.data?.data.toDate), new Date(dateFilterRange?.data?.data.fromDate)) - 1);
    dateFilterRange?.data?.data?.toDate && setDateRange([new Date(dateFilterRange?.data?.data.toDate), new Date(dateFilterRange?.data?.data.toDate)]);
    return dateFilterRange?.data?.data;
  }, [dateFilterRange]);
  return (
    <>
      <div className="h-100">
        <div className="dashboard-filter-wrapper d-flex flex-column justify-content-center">
          <div className="d-flex flex-grow-1 gap-5 filters-container flex-wrap">
            <div>
              <MultiSelectDropdown
                placeholder={"Organization"}
                searchFilterDropdownPlaceholder={"Organization"}
                searchFilterDropdownMenuList={organizationData?.data?.data}
                displayPropertyName={"organizationName"}
                valuePropertyName={"organizationId"}
                showSearchFilterDropdown
                value={organizationList.length && organizationList || [""]}
                title={title}
                onChange={(val: any) => {
                  setOrganizationList(val);
                  setHoverText(organizationData?.data?.data, val, "organizationId", "organizationName");
                  setIsDownloadAllMetricEnabled(false);
                }}
              />
            </div>
            <div>
              <MultiSelectDropdown
                placeholder={"User Role"}
                searchFilterDropdownPlaceholder={"User Role"}
                searchFilterDropdownMenuList={rolesList}
                displayPropertyName={"roleName"}
                valuePropertyName={"roleId"}
                showSearchFilterDropdown
                value={userRoles.length && userRoles || [""]}
                title={title}
                onChange={(val: any) => {
                  setUserRoles(val);
                  setHoverText(rolesList, val, "roleId", "roleName");
                  setIsDownloadAllMetricEnabled(false);
                }}
              />
            </div>
            <div className="d-flex align-items-center gap-1 datepicker-section">
              <DatePicker
                dateRange={dateRange}
                allowedDateRange={allowedDateRange}
                onChange={(val: any) => {
                  setDateRange(val);
                  setIsDownloadAllMetricEnabled(false);
                }}
              />
              <span className="help-icon">
                <Info width="13" height="13" fillColor="white" />
                <div className="help-info">
                  {"Selected date range should not exceed last " +
                    dateFilterRangeDaysCount +
                    " days."}
                  <div className="note"><strong>UTC Timezone is considered.</strong></div>
                </div>
              </span>
            </div>
            <Button
              className="apply"
              onClick={() => {
                let metrics = {
                  organizationIds: organizationList.map(String),
                  fromDate: dateRange ? dateRange[0] : null,
                  toDate: dateRange ? dateRange[1] : null,
                  roleIds: userRoles,
                }
                setFilterCriteria(metrics)
                setMetricsFilters(metrics);
                setIsDownloadAllMetricEnabled(true);
              }}
            >
              Apply
            </Button>
            {(filterCriteria?.organizationIds?.toLocaleString() ||
              filterCriteria?.toDate ||
              filterCriteria?.fromDate) && (
              <div
                className="clear-all"
                onClick={() => {
                  setDateRange(null);
                  setOrganizationList([]);
                  setUserRoles([]);
                  setFilterCriteria({
                    organizationIds: [],
                    fromDate: null,
                    toDate: null,
                    roleIds: [],
                  });
                setIsDownloadAllMetricEnabled(false);
                }}
              >
                <span className="link">Clear All</span>
              </div>
            )}
            <div className={"help-icon align-self-end download-all-metrics " +(isDownloadAllMetricEnabled ? "":"disable-download")}>
              {
                <div
                  className="download-icon-wrapper d-flex justify-content-center"
                  onClick={() => {
                    if (isDownloadAllMetricEnabled) {
                      downloadAllMetricsExcel({
                        ...filterCriteria,
                      }).then((res: any) => {
                        const blob = new Blob([res?.data?.data], {
                          type:
                            res?.data?.headers?.get("Content-Type") ||
                            "application/octet-stream",
                        });
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download =
                          Utility.getFileNameFromResponseHeaders(
                            res?.data?.headers
                          ) || "Metric.xlsx";
                        link.click();
                        URL.revokeObjectURL(link.href);
                      });
                    }
                  }}
                >
                  <Download fillColor="white" />
                </div>
              }
              <div className="help-info">
                {metricsInfoTexts.AllMetricsDownload}
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-cards-content">
          {dashboardData && dashboardData.length > 0 && (
            <div className="d-flex pl-50">
              <div className="d-flex flex-wrap cards-container w-80">
                {dashboardData.map((item: any, index: number) => {
                  return (
                    <div className="card-wrapper d-flex flex-column">
                      <div className="d-flex gap-2 flex-grow-1">
                        <div className="card-icon">
                          <RenderDashboardCardIcon
                            metricType={item?.dashboardMetricType}
                          />
                        </div>
                        <div
                          className="card-title ellipse-on-second-line flex-grow-1"
                          title={item?.dashboardMetricName}
                        >
                          {item?.dashboardMetricName}
                        </div>
                        {item?.dashboardMetricType !=
                          DashboardMetricTypeEnums.dailyAverageUsers &&
                          item?.dashboardMetricType !=
                          DashboardMetricTypeEnums.weeklyAverageUsers && (
                            <div
                              className="download-icon-wrapper d-flex justify-content-center"
                              onClick={() => {
                                downloadExcel({
                                  ...filterCriteria,
                                  dashboardMetricType:
                                    item?.dashboardMetricType,
                                }).then((res: any) => {
                                  const blob = new Blob([res?.data?.data], {
                                    type:
                                      res?.data?.headers?.get("Content-Type") ||
                                      "application/octet-stream",
                                  });
                                  const link = document.createElement("a");
                                  link.href = window.URL.createObjectURL(blob);
                                  link.download =
                                    Utility.getFileNameFromResponseHeaders(
                                      res?.data?.headers
                                    ) || "Metric.xlsx";
                                  link.click();
                                  URL.revokeObjectURL(link.href);
                                });
                              }}
                            >
                              <Download />
                            </div>
                          )}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="help-icon align-self-end">
                          <Info width="20" height="20" fillColor="#8998B7" />
                          <div className="help-info">
                            <RenderDashboardCardInfoText
                              metricType={item?.dashboardMetricType}
                              metricName={item?.dashboardMetricName}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div
                            className={
                              item?.metricCount > 99
                                ? "metric-count p-8"
                                : "metric-count"
                            }
                          >
                            {item?.metricCount}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
