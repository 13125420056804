import { useEffect, useMemo, useRef, useState } from "react";
import Button from "@mui/material/Button";
import { regex } from "../../constants/regex";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import Input from "../shared/Input/Input";
import {
  useLazyForgotPasswordQuery,
  useLazyLoginQuery,
} from "../../services/rtkQueryServices/authenticationService";
import { validationMessages } from "../../constants/forms/validationMessages";
import { ErrorMessage, LOGIN_LITERALS } from "../../constants/literals";
import { LoginResponse } from "../../models/Response/LoginResponse";
import { LoginRequest } from "../../models/Request/LoginRequest";
import { useDispatch } from "react-redux";
import { loginCredentials } from "../../redux/slices/emailSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { routePaths } from "../../constants/routePaths";
import { UserService } from "../../services/UserService";
import { errorMessages } from "../../constants/errorMessages";
import { Utility } from "../../utils/Utility";
import { errorStatusCode } from "../../constants/errorStatusCodes";
import { confirmationMessages } from "../../constants/confirmationMessages";
import { ConfigurationService } from "../../services/ConfigurationService";

export default function Login(props: any) {
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState<Boolean>();
  const initialFormValue = useMemo<any>(() => {
    return {
      email: "",
      password: "",
    };
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [requestLogin] = useLazyLoginQuery();
  const [resetPassword] = useLazyForgotPasswordQuery();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>("");
  const [timer, setTimer] = useState("00:00");
  const [secondsLeft, setSecondsLeft] = useState<number>(0);
  const [isAccountLocked, setIsAccountLocked] = useState<boolean>();
  const formInitialValues = initialFormValue;
  const validationSchema = () =>
    Yup.object({
      email: Yup.string()
        .required(validationMessages.emailRequired)
        .matches(regex.emailValidation, validationMessages.validEmail),
      password: Yup.string().required(validationMessages.passwordRequired),
    });
  const handleSubmit = (values: any) => {
    setForgotPasswordSuccess(false);
    if (UserService.isUserTokenExpired) {
      requestLogin(values)
        .then((res: any) => {
          let data: LoginResponse = res?.data?.data;
          if (res?.data?.isSuccess) {
            if (data?.isLoginSuccess) {
              setLoginErrorMessage("");
              UserService.loginCode = res?.data?.data?.tokenResponse?.token;
              dispatch(loginCredentials(values));
              navigate(routePaths.otp);
            } else if (
              data?.isAccountLocked === false &&
              data?.attemptsLeft !== null
            ) {
              setLoginErrorMessage(
                ErrorMessage.ErrorInvalidUser + data?.attemptsLeft
              );
            } else if (data?.isAccountLocked) {
              setIsAccountLocked(true);
              setSecondsLeft(data?.timeLeft);
              setLoginErrorMessage(ErrorMessage.ErrorAccountLocked);
            } else {
              setErrorMessage((res.error as any).data.message);
            }
          } else {
            setErrorMessage((res.error as any).data.message);
          }
        })
        .catch((e) => {
          setErrorMessage(LOGIN_LITERALS.somethingWentWrong);
        });
    } else {
      setErrorMessage(errorMessages.activeSessionExists);
    }
  };

  const handleChange = () => {
    setErrorMessage("");
    setLoginErrorMessage("");
    setSecondsLeft(0);
  };

  const handleFocus =()=>
  {
    setIsFocused(true);
  }

  const formRef = useRef<FormikProps<LoginRequest>>(null);

  const forgotPassword = () => {
    setIsFocused(false)
    setForgotPasswordSuccess(false);
    setErrorMessage("");
    setLoginErrorMessage("")
    formRef?.current?.setTouched({ email: true });
    formRef.current?.values.email && formRef.current?.values.email.includes("@") &&
      resetPassword({ email: formRef.current?.values.email })
        .then((res) => {
          const responseStatusCode = (res?.error as any)?.status;
          if (
            res?.data?.isSuccess ||
            responseStatusCode === errorStatusCode.notFound ||
            responseStatusCode === errorStatusCode.conflict
          ) {
            setForgotPasswordSuccess(true);
          } else {
            setErrorMessage((res.error as any).data.message);
          }
        })
        .catch((e) => {
          setErrorMessage(LOGIN_LITERALS.somethingWentWrong);
        });
  };

  const informationMessage = useMemo(() => {
    return forgotPasswordSuccess
      ? confirmationMessages.forgotPasswordConfirmation
      : props?.informationMessage
      ? props?.informationMessage
      : location?.state?.informationMessage &&
        location?.state?.informationMessage;
  }, [forgotPasswordSuccess, props]);

  useEffect(() => {
    if (secondsLeft <= 0) {
      setIsAccountLocked(false);
      setLoginErrorMessage("");
    } else {
      const interval = setInterval(() => {
        if (secondsLeft > 0) {
          setSecondsLeft(secondsLeft - 1);
          let { totalSecs, hours, minutes, seconds } = Utility.getTimeRemaining(
            secondsLeft - 1
          );
          if (totalSecs >= 0) {
            setTimer(
              (minutes > 9 ? minutes : "0" + minutes) +
                ":" +
                (seconds > 9 ? seconds : "0" + seconds)
            );
          }
        }
        if (secondsLeft <= 0 && isAccountLocked) {
          setIsAccountLocked(false);
          setLoginErrorMessage("");
          setTimer("00:00");
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [secondsLeft]);

  return (
    <>
      {informationMessage && !isFocused && (
        <span className="error-information p-2 my-3">{informationMessage}</span>
      )}
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values: any) => {
          handleSubmit(values);
        }}
        innerRef={formRef}
      >
        {(formik) => (
          <Form
            onChange={handleChange}
            onSubmit={formik.handleSubmit}
            className="login-form-container"
          >
            <div className="login-form">
              <div className="login-header fw-bold mb-4">
                {ConfigurationService.getBrandName()}
              </div>
              <Input
                onFocus={handleFocus}
                name="email"
                placeholder="Email address"
                errorMsg={
                  formik.touched.email &&
                  formik.errors.email &&
                  (formik.errors as any).email
                }
                onInput={formik.handleChange}
                value={formik.values.email}
                required
              />
              <Input
                onFocus={handleFocus}
                name="password"
                placeholder="Password"
                type="password"
                onInput={formik.handleChange}
                errorMsg={
                  formik.touched.password &&
                  formik.errors.password &&
                  (formik.errors as any).password
                }
                value={formik.values.password}
                maxLength={64}
                required
                onClickShowHidePassword = {()=>{setIsFocused(true)}}
              />
            </div>
            <div className="login-actions">
              <Button
                className={
                  !formik.isValid || !formik.dirty || secondsLeft > 0
                    ? "form-button disabled"
                    : "form-button"
                }
                type="submit"
                disabled={!formik.isValid || !formik.dirty || secondsLeft > 0}
              >
                {LOGIN_LITERALS.signIn}
              </Button>
            </div>
            <div className="d-flex justify-content-end py-2">
              <span
                onClick={() => forgotPassword()}
                className="link forgot-password"
              >
                Forgot Password?
              </span>
            </div>
            {errorMessage && (
              <>
                <div className="login-error-message p-4 mt-4">
                  <span className="error-message">{errorMessage} </span>
                </div>
              </>
            )}
            {loginErrorMessage && (
              <>
                <div className="login-error-message p-4 mt-4">
                  <span className="error-message">{loginErrorMessage} </span>
                  <span className="error-message">
                    {isAccountLocked && secondsLeft > 0 && timer}
                  </span>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
