export const API_CONSTANTS = {
  BASE_URL: process.env.REACT_APP_USER_MANAGEMENT_BASE_URL,
  BASE_RIBBON_AUTHENTICATION_BASE_URL:
    process.env.REACT_APP_RIBBON_AUTHENTICATION_BASE_URL,
  GET_ALL_USERS: "/User/GetAllUsers",
  GET_ALL_USERS_FOR_APPROVAL: "/User/GetAllUsersForApproval",
  ADD_USER: "/Authentication/AddUser",
  APPROVE_USER: "/User/ApproveUser",
  REJECT_USER: "/User/RejectUser",
  GET_Organization_BY_NPI: "/Organization/GetOrganizationByNPI?npi=",
  GET_ORGANIZATION_BY_ORGANIZATION_ID:
    "/Organization/GetOrganizationNpiAndUserDetailsById?organizationId=",
  GET_ORGANIZATION_BY_NAME:
    "/Organization/GetOrganizationByName?organizationName=",
  TOGGLE_ORGANIZATION_STATUS:
    "/Organization/ToggleOrganizationStatus?organizationId=",
  ADD_NPIS: "/Organization/AddOrganizationNpis",
  VERIFY_USER: "/Authentication/VerifyUser/",
  UPDATE_PASSWORD: "/Authentication/UpdatePassword",
  LOGIN: "/Authentication/UserManagementLogin",
  RESET_PASSWORD_INITIATION: "/Authentication/ResetPasswordInitiation",
  SET_PASSWORD_INITIATION: "/Authentication/SetPasswordInitiation/",
  FORGOT_PASSWORD: "/Authentication/UserManagementForgotPasswordInitiation",
  SET_PASSWORD: "/Authentication/SetPassword",
  CHANGE_PASSWORD: "/Authentication/ChangePassword",
  GET_ALL_ROLES: "/Authentication/GetAllRoles",
  UPDATE_USER: "/Authentication/UpdateUser",
  VALIDATE_OTP: "/OtpValidation/ValidateOTP",
  RESEND_OTP: "/OtpValidation/ResendOtp",
  REFRESH_TOKEN: "/Authentication/UserManagementRefreshToken",
  SIGN_OUT: "/Authentication/UserManagementSignOut",
  GET_ALL_ORGANIZATIONS: "/Organization/GetAllOrganizations",
  GET_DATE_FILTER_RANGE: "/Dashboard/GetDashboardDateFilterRange",
  GET_DASHBOARD_METRICS: "/Dashboard/GetDashboardMetrics",
  DOWNLOAD_METRIC_EXCEL_FILE: "/Dashboard/DownloadMetricExcelFile",
  DOWNLOAD_All_METRICS_EXCEL_FILE: "/Dashboard/DownloadAllMetricsExcelFile",
  GET_SITE_CONFIGURATIONS: "/SiteConfiguration/GetSiteConfiguration",
  GET_SITE_CONFIGURATION_INFORMATION: "/SiteConfiguration/GetSiteConfigurationInformation",
  UPDATE_SITE_CONFIGURATION: "/SiteConfiguration/UpdateSiteConfiguration"
};
