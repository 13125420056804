import { createTheme } from "@mui/material/styles";
export const theme = createTheme({
  palette: {
    primary: {
      main: "#11316E",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#FFFFFF",
    },
    text: {
      primary: "#505353",
    },
  },
  typography: {
    fontFamily: "Aileron",
    fontSize: 22.5,
  },
});

export const setTheme = (primaryMain: string, secondaryMain: string, textPrimary: string) => {
  return createTheme({
    palette: {
      primary: {
        main: primaryMain,
      },
      secondary: {
        main: secondaryMain,
      },
      text: {
        primary: textPrimary,
      },
    },
    typography: {
      fontFamily: "Aileron",
      fontSize: 22.5,
    },
  })
}
