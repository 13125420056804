export const validationMessages = {
  emailRequired: "Email address is required",
  passwordRequired: "Password is required",
  enterPassword: "Enter your password",
  reEnterPassword: "Re-enter your password",
  passwordMaxLength: "Password should not exceed more than 64 characters",
  currentPasswordRequired: "Current password is required",
  newPasswordRequired: "New password is required",
  confirmPasswordRequired: "Confirm password is required",
  otpRequired: "OTP is required",
  otpLength: "Must be 6 digits",
  passwordLength:
    "Password is too short. Must contains minimum of 8 characters",
  passwordRegexMatch:
    "Password must contain at least one uppercase letter, one lowercase letter, one special character and one number",
  confirmPassword: "Passwords must match",
  firstNameRequired: "First name is required",
  lastNameRequired: "Last Name is required",
  validEmail: "Enter valid email address",
  firstNameRegexMatch:
    "First name must only contain alphabets and white spaces",
  lastNameRegexMatch: "Last name must only contain alphabets and white spaces",
  firstNameCharacterLimit: "First name should not exceed 64 characters",
  lastNameCharacterLimit: "Last name should not exceed 64 characters",
  userApproval: "Cannot approve the user when organization is disabled.",
  isRequired(fieldName: string) {
    return `${fieldName} is required`;
  },
  alphabetsAndSpaceValidation(fieldName: string) {
    return `${fieldName} must only contain alphabets and white spaces`;
  },
  maxlength(fieldName: string, length: number) {
    return `${fieldName} should not exceed more than ${length} characters`;
  },
  minlength(fieldName: string, length: number) {
    return `${fieldName} should contain atleast ${length} characters`;
  },
  validColorCode: "Enter a valid Color code"
};
