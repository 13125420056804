import baseApi from "./baseApiService";
import { API_CONSTANTS } from "../../constants/apiConstants";
export const dashboardApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDateFilterRange: builder.query({
      query: () => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_DATE_FILTER_RANGE}`,
        method: "GET",
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
      }),
    }),
    getDashboardMetrics: builder.query({
      query: (body) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_DASHBOARD_METRICS}`,
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
      keepUnusedDataFor: 0,
    }),
    downloadExcelForDashboardMetric: builder.query({
      query: (body) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.DOWNLOAD_METRIC_EXCEL_FILE}`,
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
        responseHandler: async (response) => {
          const blobData = await response.blob();
          return blobData;
        },
        cache: 'no-cache'
      }),
      transformResponse: (data, meta: any, args) => {
        return { data, headers: meta?.response?.headers }
      }
    }),
    downloadAllMetricsExcelForDashboardMetric: builder.query({
      query: (body) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.DOWNLOAD_All_METRICS_EXCEL_FILE}`,
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
        responseHandler: async (response) => {
          const blobData = await response.blob();
          return blobData;
        },
        cache: 'no-cache'
      }),
      transformResponse: (data, meta: any, args) => {
        return { data, headers: meta?.response?.headers }
      }
    }),
  }),
});
export const { useGetDateFilterRangeQuery, useLazyGetDashboardMetricsQuery, useLazyDownloadExcelForDashboardMetricQuery, useLazyDownloadAllMetricsExcelForDashboardMetricQuery} = dashboardApi;
