import baseApi from "./baseApiService";
import { API_CONSTANTS } from "../../constants/apiConstants";

export const organizationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationByOrganizationId: builder.query({
      query: (organizationId) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_ORGANIZATION_BY_ORGANIZATION_ID}${organizationId}`,
        method: "GET",
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
      }),
    }),
    toggleOrganizationStatus: builder.query({
      query: (organizationId) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.TOGGLE_ORGANIZATION_STATUS}${organizationId}`,
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    addNpis: builder.query({
      query: (requestBody) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.ADD_NPIS}`,
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    getOrganizationByName: builder.query({
      query: (OrganizationName) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_ORGANIZATION_BY_NAME}${OrganizationName}`,
        method: "GET",
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
      }),
    }),
    getAllOrganizationNames: builder.query({
      query: () => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_ALL_ORGANIZATIONS}`,
        method: "GET",
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
      }),
    }),
  }),
});
export const {
  useLazyGetOrganizationByOrganizationIdQuery,
  useGetOrganizationByOrganizationIdQuery,
  useLazyToggleOrganizationStatusQuery,
  useLazyGetOrganizationByNameQuery,
  useLazyAddNpisQuery,
  useGetAllOrganizationNamesQuery,
} = organizationApi;
