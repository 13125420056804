import "./App.scss";
import React, { useEffect, useState } from "react";
import { LoaderProvider } from "./context/LoaderProvider";
import RoutesConfig from "./config/routesConfig";
import { AuthProvider } from "./context/AuthProvider";
import { useLazyGetSiteConfigurationsQuery } from "./services/rtkQueryServices/SiteConfigurationApi";
import { ConfigurationService } from "./services/ConfigurationService";
import { ThemeProvider } from "@emotion/react";
import { theme, setTheme } from "./components/shared/Widget/Theme/MaterialUiTheme";

function App() {
  const [getSiteConfigurations] = useLazyGetSiteConfigurationsQuery();
  const [MUItheme, setMUITheme] = useState<any>(theme);
  useEffect(() => {  
    getSiteConfigurations(null).then((res) => {
      if(res.data?.isSuccess) {
        ConfigurationService.applyConfigs(res.data?.data);
        setMUITheme(setTheme(ConfigurationService.getPrimaryColor() || theme.palette.primary.main, theme.palette.secondary.main, theme.palette.text.primary));
      }
    })
  }, []);
  return (
    <ThemeProvider theme={MUItheme}>
      <AuthProvider>
        <LoaderProvider>
          <RoutesConfig />
        </LoaderProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
