import React from "react";
import "./UtcDateTime.scss";
import { Utility } from "../../../utils/Utility";

function UtcDateTime(props: any) {
  return (
    <div className="date-time-container d-flex flex-column">
      <div
        className="date text-overflow-ellipse"
        title={Utility.getDateInParticularFormat(props?.value)}
      >
        {Utility.getDateInParticularFormat(props?.value)}
      </div>
      <div
        className="time text-overflow-ellipse"
        title={"UTC - " + Utility.getTime(props?.value)}
      >
        UTC- {Utility.getTime(props?.value)}
      </div>
    </div>
  );
}

export default UtcDateTime;
