import { useEffect, useState } from "react";
import Input from "../Input/Input";
import "./GridHeader.scss";
import { ReactComponent as Search } from "../../../assets/svg/search.svg";

function GridHeader(props: any) {
  const [inputValue, setInputValue] = useState<string>(
    props?.searchString || ""
  );
  function handleSearchInput(value: string): void {
    setInputValue(value);
  }

  function handleEnter(e: any) {
    if (e.key === "Enter") {
      e.target.blur();
      search();
    }
  }
  const search = (isClear: boolean = false) => {
    if (props.setSearchQuery) {
      if (isClear) {
        props.setSearchQuery("");
      } else props.setSearchQuery(inputValue);
    }
  };

  useEffect(() => {
    setInputValue("");
  }, [props?.clearInput]);

  return (
    <>
      {(props?.canSearch || props?.showFilterIcon || props?.actionButtons) && (
        <div>
          <div className="MUI-grid-outer-header">
            <div className="grid-outer-header-left-container me-2">
              {props.headerContent && (
                <div>
                  <div
                    className="grid-header-title me-3"
                    title={props?.headerContent}
                  >
                    {props?.headerContent}
                  </div>
                </div>
              )}
              {props.subHeading && (
                <div
                  className="name-wrapper cursor-pointer"
                  title={props?.subHeading}
                  onClick={() => {
                    props?.subHeadingOnclick();
                  }}
                >
                  {props?.subHeading}
                </div>
              )}
            </div>
            <div className="grid-outer-header-right-container">
              <div className="d-flex w-100 me-2">
                {props.canSearch && (
                  <div
                    className="grid-header-search flex-grow-1"
                    title={`Search ` + (props.searchFieldName || "")}
                  >
                    <Input
                      isFormElement={false}
                      showClear={inputValue?.length > 0}
                      onClear={() => {
                        setInputValue("");
                        search(true);
                      }}
                      placeholder={`Search ` + (props.searchFieldName || "")}
                      suffix={
                        <Search
                          onClick={() => {
                            search();
                          }}
                        />
                      }
                      onInput={(e: any) =>
                        handleSearchInput((e.target as HTMLInputElement).value)
                      }
                      value={inputValue}
                      onKeyDown={handleEnter}
                    ></Input>
                  </div>
                )}
                <div className="d-flex flex-grow-1 justify-content-end">
                  {props.actionButtons && props.actionButtons}
                </div>
                {props.showFilterIcon && <>{props.filterTemplate}</>}
              </div>
            </div>
          </div>
          {props?.showSearchFilterData &&
            props?.showSearchFilterData?.length > 2 && (
              <div className="mb-2 font-style-italic filter-data-wrapper">
                Showing search results for "{props?.showSearchFilterData}
                ".
              </div>
            )}
          {props?.showSearchFilterData &&
            props?.showSearchFilterData?.length > 0 &&
            props?.showSearchFilterData?.length <= 2 && (
              <div className="mb-2 search-info-wrapper font-style-italic">
                Enter a minimum of 3 characters for the search.
              </div>
            )}
        </div>
      )}
    </>
  );
}
export default GridHeader;
