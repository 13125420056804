import { createSlice } from "@reduxjs/toolkit";
import { sliceNames } from "../../constants/sliceNames";
export const loginCredentialSlice = createSlice({
  name: sliceNames.emailName,
  initialState: { value: undefined },
  reducers: {
    loginCredentials: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { loginCredentials } = loginCredentialSlice.actions;
export default loginCredentialSlice.reducer;
