export const routePaths = {
  userManagement: "/usermanagement",
  setPassword: "/setPassword/:userId",
  dashboard: "/dashboard",
  siteConfiguration: "/siteConfiguration",
  resetPassword: "/resetPassword/:userId",
  updatePassword: "updatePassword",
  login: "/login",
  otp: "/otp",
  base: "/",
  wild: "*",
  sessionExpiry: "/sessionExpired",
};
