import Button from "@mui/material/Button";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { regex } from "../../constants/regex";
import { useSelector } from "react-redux";
import { ReactComponent as TimelineLogo } from "../../../src/assets/svg/timelineLogo.svg";
import { Formik, Form, Field } from "formik";
import Input from "../shared/Input/Input";
import { ReactComponent as Email } from "../../assets/svg/mail.svg";

import * as Yup from "yup";
import {
  useLazyResendOtpQuery,
  useLazyValidateOtpQuery,
} from "../../services/rtkQueryServices/authenticationService";
import { UserService } from "../../services/UserService";
import { routePaths } from "../../constants/routePaths";
import { useAuth } from "../../context/AuthProvider";
import { validationMessages } from "../../constants/forms/validationMessages";
import { LOGIN_LITERALS } from "../../constants/literals";
import { Utility } from "../../utils/Utility";
import { dataStore } from "../../constants/dataStore";
import { errorMessages } from "../../constants/errorMessages";
import { Roles } from "../../constants/data/Roles";
export default function OtpValidation() {
  const navigate = useNavigate();
  const auth = useAuth();
  const otpReSendTime = 30;
  const [resendOtpRequest] = useLazyResendOtpQuery();
  const [validateOtp] = useLazyValidateOtpQuery();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [resendOtpTimer, setResendOtpTimer] = useState<any>(otpReSendTime);
  const loginDetails = useSelector((state: any) => state?.email?.value);
  const initialFormValue = useMemo(() => {
    if (!loginDetails) navigate(routePaths.login);
    return {
      otp: "",
      userEmail: loginDetails?.email,
    };
  }, [loginDetails, navigate]);

  useEffect(() => {
    if (!loginDetails) {
      navigate(routePaths.login);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (resendOtpTimer > 0) {
        setResendOtpTimer(resendOtpTimer - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [resendOtpTimer]);

  const resendOtp = () => {
    setErrorMessage("");
    setResendOtpTimer(otpReSendTime);
    resendOtpRequest({ email: loginDetails?.email }).catch((e: any) => {
      setErrorMessage(LOGIN_LITERALS.somethingWentWrong);
    });
  };
  const validationSchema = () =>
    Yup.object({
      otp: Yup.string()
        .required(validationMessages.otpRequired)
        .min(6, validationMessages.otpLength)
        .max(6, validationMessages.otpLength),
    });

  const handleChange = () => {
    setErrorMessage("");
  };

  return (
    <Formik
      initialValues={initialFormValue}
      validationSchema={validationSchema}
      onSubmit={(values: any) => {
        if (UserService.isUserTokenExpired) {
          validateOtp(values)
            .then((res: any) => {
              if (res?.data?.isSuccess) {
                auth?.logIn(res.data.data);
                localStorage.removeItem(dataStore.login_code);
                if(UserService.getClaims?.role === Roles.supportUser)
                {
                  navigate(routePaths.dashboard);
                }
                else 
                {
                  navigate(routePaths.base);
                }
                setErrorMessage("");
              } else {
                setErrorMessage((res.error as any)?.data?.message);
              }
            })
            .catch((e: any) => {
              setErrorMessage(LOGIN_LITERALS.somethingWentWrong);
            });
        } else {
          setErrorMessage(errorMessages.activeSessionExists);
        }
      }}
    >
      {(formik) => (
        <Form
          onChange={handleChange}
          onSubmit={formik.handleSubmit}
          className="login-form-container"
        >
          <div className="login-form">
            <div className="login-header">{LOGIN_LITERALS.enterOtp}</div>
            <div className="login-header-info mb-4">
              {LOGIN_LITERALS.otpInfo}
            </div>
            <div className="login-mail mb-2">
              <Email className="me-2" />
              {loginDetails?.email}
            </div>
            <Input
              name="otp"
              placeholder={"Enter OTP"}
              onKeyDown={Utility.allowOnlyNumericsAndCtrlV}
              errorMsg={
                formik.touched.otp &&
                formik.errors.otp &&
                (formik.errors as any).otp
              }
              required
              value={formik.values.otp}
              maxLength={6}
              onInput={formik.handleChange}
            />
          </div>
          <div className="d-flex flex-wrap justify-content-end">
            <Button
              variant="text"
              className="resend-otp"
              type="button"
              onClick={() => {
                formik.resetForm();
                resendOtp();
              }}
              disabled={resendOtpTimer > 0}
            >
              <div>
                {resendOtpTimer > 0 ? (
                  `00:${resendOtpTimer.toLocaleString("en-us", {
                    minimumIntegerDigits: 2,
                  })}`
                ) : (
                  <span>{LOGIN_LITERALS.resendOtp}</span>
                )}
              </div>
              <div>
                <TimelineLogo className="ms-2" />
              </div>
            </Button>
          </div>
          {errorMessage && (
            <span className="error-message">{errorMessage}</span>
          )}
          <div className="login-actions">
            <Button
              className={
                !formik.errors.otp ? "form-button" : "form-button disabled"
              }
              type="submit"
              disabled={!formik.isValid || !formik.dirty}
            >
              {LOGIN_LITERALS.validate}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
