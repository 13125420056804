import { dataStore } from "../constants/dataStore";
import { authClaims } from "../models/authentication/authClaims";
import jwtDecode from "jwt-decode";
import { routePaths } from "../constants/routePaths";

export class UserService {
  static get isLoggedIn(): boolean {
    return localStorage.getItem(dataStore.access_token) ? true : false;
  }

  static get accessToken(): string | null {
    return localStorage.getItem(dataStore.access_token);
  }

  static set accessToken(token: string | null) {
    token && localStorage.setItem(dataStore.access_token, token);
  }

  static get refreshToken(): string | null {
    return localStorage.getItem(dataStore.refresh_token);
  }

  static set refreshToken(token: string | null) {
    token && localStorage.setItem(dataStore.refresh_token, token);
  }

  static get getClaims(): authClaims | null {
    let token = localStorage.getItem(dataStore.access_token);
    return token ? jwtDecode<authClaims>(token) : null;
  }

  static get loginCode(): string | null {
    return localStorage.getItem(dataStore.login_code);
  }

  static set loginCode(token: string | null) {
    token && localStorage.setItem(dataStore.login_code, token);
  }

  static get userActiveTab(): string | null {
    return sessionStorage.getItem(dataStore.active_tab);
  }

  static set userActiveTab(activeTabId: string | null) {
    activeTabId && sessionStorage.setItem(dataStore.active_tab, activeTabId);
  }

  static logOut(navigateToLogin: boolean = true) {
    localStorage.removeItem(dataStore.access_token);
    localStorage.removeItem(dataStore.refresh_token);
    localStorage.removeItem(dataStore.login_code);
    sessionStorage.removeItem(dataStore.active_tab);
    navigateToLogin && window.location.replace(routePaths.login);
  }

  static redirectToSessionExpiry() {
    this.logOut(false);
    window.location.replace(routePaths.sessionExpiry);
  }

  static get isUserTokenExpired(): boolean {
    return this.accessToken
      ? new Date(jwtDecode<authClaims>(this.accessToken).exp * 1000) <
          new Date()
      : true;
  }

  static logIn(tokenResponse: any) {
    this.accessToken = tokenResponse?.token;
    this.refreshToken = tokenResponse?.refreshToken;
  }
}
