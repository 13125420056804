import "./SideNav.scss";
import { ReactComponent as Dashboard } from "../../../.././assets/svg/dashboard.svg";
import { ReactComponent as UserManagement } from "../../../.././assets/svg/user_management.svg";
import Navigation from "../Navigation/Navigation";
import { routePaths } from "../../../../constants/routePaths";
import { ReactComponent as Gear } from "../../../.././assets/svg/Gear.svg";
export interface sideNavProps {
  setIsSideNavOpen: any;
  isSideNavOpen: boolean;
}
function SideNav(props: sideNavProps) {
  let routes = [
    {
      text: "Dashboard",
      icon: Dashboard,
      link: routePaths.dashboard,
    },
    {
      text: "User Management",
      icon: UserManagement,
      link: routePaths.userManagement,
    },
    {
      text: "Brand Configuration",
      icon: Gear,
      link: routePaths.siteConfiguration
    },
  ];
  const navigation = routes.map((item, index) => (
    <li className="mb-4" key={item.text}>
      <Navigation {...props} navItem={item} />
    </li>
  ));
  return (
    <>
      <div className="side-nav-wrapper">
        <ul className="list-unstyled mb-0">{navigation}</ul>
      </div>
      <div className="d-flex flex-column side-nav-footer-wrapper align-items-center"></div>
    </>
  );
}

export default SideNav;
