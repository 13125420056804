import React, { useEffect, useMemo, useState } from "react";
import { Field, Formik } from "formik";
import { errorMessages } from "../../../constants/errorMessages";
import * as Yup from "yup";
import { regex } from "../../../constants/regex";
import Input from "../../shared/Input/Input";
import { userManagement_LABELS } from "../../../constants/labels/userManagement/userManagement";
import Button from "@mui/material/Button";
import {
  useGetAllRolesQuery,
  useLazyAddUserQuery,
  useLazyUpdateUserQuery,
} from "../../../services/rtkQueryServices/authenticationService";
import { UserRequest } from "../../../models/UserManagement/userRequestModel";
import Dropdown from "../../shared/DropDown/Dropdown";
import { useLazyGetOrganizationByNameQuery } from "../../../services/rtkQueryServices/OrganizationApi";
import { FORM_TYPE } from "../../../enums/formTypes";
import { Roles } from "../../../constants/data/Roles";
import { UserService } from "../../../services/UserService";
import { Switch } from "@mui/material";
import { Utility } from "../../../utils/Utility";

const AddUserHandler = (props: any) => {
  const {
    practiceManagerOrganizationDetails,
    isOpenedFromOrganization,
    defaultRole,
  } = props;
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [setOrganizationsHandler, organizationsHandler] =
    useLazyGetOrganizationByNameQuery();
  const [organizationsDetails, setOrganizationsDetails] = useState([]);
  const [setAddUser, addUser] = useLazyAddUserQuery();
  const [updateUser, updateUserResponse] = useLazyUpdateUserQuery();
  const [isAddNewOrganization, setIsAddNewOrganization] =
    useState<boolean>(false);
  const [practiceManagerRoleId, setPracticeManagerRoleId] = useState();
  const [practitionerRolesId, setPractitionerRolesId] = useState();
  const [showOrganizationDetails, setShowOrganizationDetails] =
    useState<boolean>(false);
  const roles = useGetAllRolesQuery(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { initialFormValues, formRef, formType, setFormType } = props;
  useEffect(() => {
    if (
      (formType !== FORM_TYPE.ADD || isOpenedFromOrganization) &&
      UserService.getClaims?.role === Roles.supportUser
    ) {
      setIsAddNewOrganization(true);
    }
  }, [formType, isOpenedFromOrganization]);

  useEffect(() => {
    if (organizationsHandler.isSuccess) {
      if (organizationsHandler.data?.data?.length > 0) {
        let menuItems = organizationsHandler.data?.data?.map((item: any) => {
          return {
            value: {
              organizationId: item?.organizationId,
              npis: item?.npis,
              organizationName: item?.organizationName,
              organizationAddress: item?.organizationAddress,
            },
            displayValue: item?.organizationName + " - " + item?.npis,
          };
        });
        setOrganizationsDetails(menuItems);
        setIsLoading(false);
      } else {
        setOrganizationsDetails([]);
        setIsLoading(false);
      }
    }
  }, [organizationsHandler]);

  const menuItemRoles = useMemo(() => {
    let menuItems = roles.data?.data
      ?.filter(
        (item: any) => item?.roleName?.toUpperCase() !== Roles.supportUser
      )
      .map((item: any) => {
        if (item?.roleName === Roles.practiceManager) {
          setPracticeManagerRoleId(item?.roleId);
        } else if (item?.roleName === Roles.practitioner) {
          setPractitionerRolesId(item?.roleId);
        }
        return {
          value: item?.roleId,
          displayValue: item?.roleName,
        };
      });
    return menuItems;
  }, [roles]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(errorMessages.required("First Name"))
      .max(64, errorMessages.characterLimit("First Name", 64))
      .matches(
        regex.nameValidation,
        errorMessages.alphabetsAndSpaceValidation("First Name")
      ),
    lastName: Yup.string()
      .required(errorMessages.required("Last Name"))
      .max(64, errorMessages.characterLimit("Last Name", 64))
      .matches(
        regex.nameValidation,
        errorMessages.alphabetsAndSpaceValidation("First Name")
      ),
    emailAddress: Yup.string()
      .required(errorMessages.required("Email address"))
      .matches(regex.emailValidation, errorMessages.invalidEmail)
      .max(128, errorMessages.characterLimit("Email address", 128)),
    contactNumber: Yup.string().max(
      28,
      errorMessages.characterLimit("Contact Number", 28)
    ),
    npi: isAddNewOrganization
      ? isOpenedFromOrganization ||
        showOrganizationDetails ||
        formType !== FORM_TYPE.ADD
        ? Yup.string()
            .required(errorMessages.required("NPI"))
            .matches(regex.commaSeparatedNpi, errorMessages.npiRegexMisMatch)
        : Yup.string()
            .required(errorMessages.required("NPI"))
            .length(10, errorMessages.npiLengthError)
      : Yup.string(),
    organizationName: isAddNewOrganization
      ? Yup.string()
          .required(errorMessages.required("Organization Name"))
          .max(100, errorMessages.characterLimit("Organization Name", 100))
      : Yup.string(),
    organizationAddress: Yup.string().max(
      250,
      errorMessages.characterLimit("Organization Address", 250)
    ),
    organizationId: !isAddNewOrganization
      ? Yup.string().required(errorMessages.required("Organization Details"))
      : Yup.string(),
    isActive: Yup.boolean(),
  });

  const organizationDropDownChange = (formik: any, value: any) => {
    formik.setFieldValue("organizationId", value?.value?.organizationId);
    formik.setFieldValue("npi", value?.value?.npis || "");
    formik.setFieldValue(
      "organizationName",
      value?.value?.organizationName || ""
    );
    formik.setFieldValue(
      "organizationAddress",
      value?.value?.organizationAddress || ""
    );
    setShowOrganizationDetails(true);
  };

  const role = UserService.getClaims?.role?.toUpperCase();

  useEffect(() => {
    formRef?.current?.validateForm();
  }, [formRef?.current?.values]);

  return (
    <div className="registration-form-container">
      {(formType === FORM_TYPE.ADD || initialFormValues) &&
        (practiceManagerRoleId || practitionerRolesId) && (
          <Formik
            innerRef={formRef}
            initialValues={
              formType === FORM_TYPE.ADD
                ? {
                  emailAddress: "",
                  firstName: "",
                  lastName: "",
                  contactNumber: "",
                  npi: role === Roles.practiceManager
                    ? practiceManagerOrganizationDetails?.npis?.join(", ") || "" 
                    : role === Roles.supportUser && isOpenedFromOrganization 
                    ? practiceManagerOrganizationDetails?.npis?.join(", ") || "" 
                    : "",
                  roleId: defaultRole === Roles.practiceManager
                    ? practiceManagerRoleId
                    : defaultRole === Roles.practitioner
                    ? practitionerRolesId
                    : role === Roles.supportUser
                    ? practiceManagerRoleId
                    : practitionerRolesId,
                  organizationId: role === Roles.practiceManager
                    ? practiceManagerOrganizationDetails?.organizationId || "" 
                    : role === Roles.supportUser && isOpenedFromOrganization  
                    ? practiceManagerOrganizationDetails?.organizationId || "" 
                    : "",
                  organizationName: role === Roles.practiceManager
                    ? practiceManagerOrganizationDetails?.organizationName || "" 
                    : role === Roles.supportUser && isOpenedFromOrganization 
                    ? practiceManagerOrganizationDetails?.organizationName || "" 
                    : "",
                  organizationAddress: role === Roles.practiceManager
                    ? practiceManagerOrganizationDetails?.organizationAddress || "" 
                    : role === Roles.supportUser && isOpenedFromOrganization 
                    ? practiceManagerOrganizationDetails?.organizationAddress || "" 
                    : "",
                }
                : {
                    ...initialFormValues,
                    contactNumber: initialFormValues.contactNumber
                      ? initialFormValues.contactNumber
                      : "",
                    npi: initialFormValues.npis,
                  }
            }
            validationSchema={validationSchema}
            onSubmit={async (values: UserRequest) => {
              if (values.organizationId === "") {
                values.organizationId = undefined;
              }
              if(formRef.current?.dirty)
              {
              const response = await (formType === FORM_TYPE.ADD
                ? setAddUser(values)
                : updateUser(values));

              if (response.isSuccess) {
                props?.onClose();
              } else if (response.isError) {
                setErrorMessage((response?.error as any)?.data?.message);
              }
            }
            else{
              props?.onClose();
            }
            }}
          >
            {(formik) => (
              <form
                onKeyDown={Utility.preventSubmitOnEnter}
                onSubmit={formik.handleSubmit}
                className="d-flex flex-column p-3 py-0"
              >
                <Dropdown
                  menuItems={menuItemRoles}
                  name={"roleId"}
                  formik={formik}
                  label="Role"
                  required
                  placeholder="Role"
                  disabled={
                    formType === FORM_TYPE.VIEW ||
                    (formType != FORM_TYPE.EDIT &&
                      isAddNewOrganization &&
                      !isOpenedFromOrganization) ||
                    ((!initialFormValues?.isOrganizationActive ||
                      !initialFormValues?.isActive) &&
                      formType !== FORM_TYPE.ADD)
                  }
                />
                {!isAddNewOrganization &&
                UserService.getClaims?.role?.toUpperCase() ===
                  Roles.supportUser ? (
                  <>
                    <Dropdown
                      label={userManagement_LABELS.organization}
                      placeholder={userManagement_LABELS.organization}
                      dropdownSearchInputPlaceHolder={
                        userManagement_LABELS.searchOrganization
                      }
                      name="organizationId"
                      menuItems={organizationsDetails}
                      formik={formik}
                      searchable
                      onChange={(value: any) => {
                        organizationDropDownChange(formik, value);
                      }}
                      required={!isAddNewOrganization}
                      onInputChange={(event: any, newInputValue: string) => {
                        if (newInputValue.length >= 3) {
                          setIsLoading(true);
                          setOrganizationsHandler(newInputValue);
                        }
                      }}
                      onClearClick={() => {
                        setShowOrganizationDetails(false);
                      }}
                      isLoading={isLoading}
                      errorMsg={
                        formik.touched.organizationId &&
                        formik.errors.organizationId &&
                        (formik.errors as any).organizationId
                      }
                    />
                    {UserService.getClaims?.role?.toUpperCase() ===
                      Roles.supportUser &&
                      showOrganizationDetails && (
                        <>
                          <Input
                            name="organizationName"
                            placeholder={userManagement_LABELS.organizationName}
                            label={userManagement_LABELS.organizationName}
                            onInput={(e) => {
                              formik.handleChange(e);
                            }}
                            errorMsg={
                              formik.touched.organizationName &&
                              formik.errors.organizationName &&
                              (formik.errors as any).organizationName
                            }
                            value={formik.values.organizationName}
                            disabled={
                              formType !== FORM_TYPE.ADD ||
                              isOpenedFromOrganization ||
                              ((!initialFormValues?.isOrganizationActive ||
                                !initialFormValues?.isActive) &&
                                formType !== FORM_TYPE.ADD) ||
                              true
                            }
                            required={isAddNewOrganization}
                          />
                          <Input
                            name="organizationAddress"
                            placeholder={
                              userManagement_LABELS.organizationAddress
                            }
                            label={userManagement_LABELS.organizationAddress}
                            onInput={(e) => {
                              formik.handleChange(e);
                            }}
                            errorMsg={
                              formik.touched.organizationAddress &&
                              formik.errors.organizationAddress &&
                              (formik.errors as any).organizationAddress
                            }
                            value={formik.values.organizationAddress}
                            disabled={
                              formType !== FORM_TYPE.ADD ||
                              isOpenedFromOrganization ||
                              ((!initialFormValues?.isOrganizationActive ||
                                !initialFormValues?.isActive) &&
                                formType !== FORM_TYPE.ADD) ||
                              true
                            }
                          />
                          <Input
                            name="npi"
                            placeholder={"National Provider Identifier"}
                            label={userManagement_LABELS.npi}
                            onInput={(e) => {
                              formik.handleChange(e);
                            }}
                            errorMsg={
                              formik.touched.npi &&
                              formik.errors.npi &&
                              (formik.errors as any).npi
                            }
                            value={formik.values.npi}
                            disabled={
                              formType !== FORM_TYPE.ADD ||
                              isOpenedFromOrganization ||
                              ((!initialFormValues?.isOrganizationActive ||
                                !initialFormValues?.isActive) &&
                                formType !== FORM_TYPE.ADD) ||
                              true
                            }
                            required={isAddNewOrganization}
                          />
                        </>
                      )}
                  </>
                ) : (
                  UserService.getClaims?.role?.toUpperCase() ===
                    Roles.supportUser && (
                    <>
                      <Input
                        name="organizationName"
                        placeholder={userManagement_LABELS.organizationName}
                        label={userManagement_LABELS.organizationName}
                        onInput={(e) => {
                          formik.handleChange(e);
                        }}
                        errorMsg={
                          formik.touched.organizationName &&
                          formik.errors.organizationName &&
                          (formik.errors as any).organizationName
                        }
                        value={formik.values.organizationName}
                        disabled={
                          formType !== FORM_TYPE.ADD ||
                          isOpenedFromOrganization ||
                          ((!initialFormValues?.isOrganizationActive ||
                            !initialFormValues?.isActive) &&
                            formType !== FORM_TYPE.ADD)
                        }
                        required={isAddNewOrganization}
                      />
                      <Input
                        name="organizationAddress"
                        placeholder={userManagement_LABELS.organizationAddress}
                        label={userManagement_LABELS.organizationAddress}
                        onInput={(e) => {
                          formik.handleChange(e);
                        }}
                        errorMsg={
                          formik.touched.organizationAddress &&
                          formik.errors.organizationAddress &&
                          (formik.errors as any).organizationAddress
                        }
                        value={formik.values.organizationAddress}
                        disabled={
                          formType !== FORM_TYPE.ADD ||
                          isOpenedFromOrganization ||
                          ((!initialFormValues?.isOrganizationActive ||
                            !initialFormValues?.isActive) &&
                            formType !== FORM_TYPE.ADD)
                        }
                      />
                      <Input
                        name="npi"
                        placeholder={"National Provider Identifier"}
                        label={userManagement_LABELS.npi}
                        onInput={(e) => {
                          formik.handleChange(e);
                        }}
                        errorMsg={
                          formik.touched.npi &&
                          formik.errors.npi &&
                          (formik.errors as any).npi
                        }
                        value={formik.values.npi}
                        disabled={
                          formType !== FORM_TYPE.ADD ||
                          isOpenedFromOrganization ||
                          ((!initialFormValues?.isOrganizationActive ||
                            !initialFormValues?.isActive) &&
                            formType !== FORM_TYPE.ADD)
                        }
                        required={isAddNewOrganization}
                      />
                    </>
                  )
                )}
                {formType === FORM_TYPE.ADD &&
                  UserService.getClaims?.role?.toUpperCase() ===
                    Roles.supportUser && (
                    <div className="d-flex justify-content-end">
                      {!isOpenedFromOrganization && (
                        <div
                          className={
                            "cursor-pointer new-existing-org-wrapper " +
                            ((isOpenedFromOrganization ||
                              practitionerRolesId === formik?.values?.roleId) &&
                              "disable-org-details")
                          }
                          onClick={() => {
                            if (
                              !isOpenedFromOrganization &&
                              practitionerRolesId !== formik?.values?.roleId
                            ) {
                              formik.setFieldTouched(
                                "organizationName",
                                false
                              );
                              formik.setFieldTouched(
                                "organizationAddress",
                                false
                              );
                              formik.setFieldTouched(
                                "npi",
                                false
                              )
                              if (isAddNewOrganization) {
                                formik.setFieldValue("organizationAddress", "");
                                setIsAddNewOrganization(false);
                                setShowOrganizationDetails(false);
                              } else {
                                formik.setFieldTouched("organizationId", false);
                                setIsAddNewOrganization(true);
                                setShowOrganizationDetails(false);
                              }
                              if (formik.dirty) {
                                formik.setFieldValue("organizationId", "");
                                formik.setFieldValue("organizationName", "");
                                formik.setFieldValue("organizationAddress", "");
                                formik.setFieldValue("npi", "");
                              }
                              setIsAddNewOrganization(!isAddNewOrganization);
                            }
                          }}
                        >
                          {!isAddNewOrganization
                            ? "New Org? - Click Here"
                            : "Existing Org? - Click Here"}
                        </div>
                      )}
                    </div>
                  )}

                <Input
                  name="firstName"
                  placeholder={userManagement_LABELS.firstName}
                  label={userManagement_LABELS.firstName}
                  errorMsg={
                    formik.touched.firstName &&
                    formik.errors.firstName &&
                    (formik.errors as any).firstName
                  }
                  value={formik.values.firstName}
                  onInput={(e) => {
                    formik.handleChange(e);
                  }}
                  required
                  disabled={
                    formType === FORM_TYPE.VIEW ||
                    ((!initialFormValues?.isOrganizationActive ||
                      !initialFormValues?.isActive) &&
                      formType !== FORM_TYPE.ADD)
                  }
                />
                <Input
                  name="lastName"
                  placeholder={userManagement_LABELS.lastName}
                  label={userManagement_LABELS.lastName}
                  errorMsg={
                    formik.touched.lastName &&
                    formik.errors.lastName &&
                    (formik.errors as any).lastName
                  }
                  onInput={(e) => {
                    formik.handleChange(e);
                  }}
                  value={formik.values.lastName}
                  required
                  disabled={
                    formType === FORM_TYPE.VIEW ||
                    ((!initialFormValues?.isOrganizationActive ||
                      !initialFormValues?.isActive) &&
                      formType !== FORM_TYPE.ADD)
                  }
                />
                <Input
                  name="emailAddress"
                  placeholder={userManagement_LABELS.emailAddress}
                  label={userManagement_LABELS.emailAddress}
                  onInput={(e) => {
                    formik.handleChange(e);
                  }}
                  errorMsg={
                    formik.touched.emailAddress &&
                    formik.errors.emailAddress &&
                    (formik.errors as any).emailAddress
                  }
                  value={formik.values.emailAddress}
                  required
                  disabled={
                    formType !== FORM_TYPE.ADD ||
                    ((!initialFormValues?.isOrganizationActive ||
                      !initialFormValues?.isActive) &&
                      formType !== FORM_TYPE.ADD)
                  }
                />
                <Input
                  name="contactNumber"
                  placeholder={userManagement_LABELS.contactNumber}
                  label={userManagement_LABELS.contactNumber}
                  onInput={(e) => {
                    formik.handleChange(e);
                  }}
                  errorMsg={
                    formik.touched.contactNumber &&
                    formik.errors.contactNumber &&
                    (formik.errors as any).contactNumber
                  }
                  value={formik.values.contactNumber}
                  disabled={
                    formType === FORM_TYPE.VIEW ||
                    ((!initialFormValues?.isOrganizationActive ||
                      !initialFormValues?.isActive) &&
                      formType !== FORM_TYPE.ADD)
                  }
                />
                {(formType === FORM_TYPE.EDIT ||
                  formType === FORM_TYPE.VIEW) && (
                  <div className="d-flex flex-direction-column align-items-center">
                    <label>User Status</label>
                    <div className="d-flex align-items-center">
                      <Field name="isActive">
                        {({ field }: any) => (
                          <Switch
                            className={
                              formType === FORM_TYPE.VIEW ? "disabled" : ""
                            }
                            name="isActive"
                            onChange={(e) => {
                              formik.setFieldValue(
                                field.name,
                                e.target.checked
                              );
                            }}
                            disabled={
                              formType === FORM_TYPE.VIEW ||
                              (!initialFormValues?.isOrganizationActive &&
                                formType !== FORM_TYPE.ADD)
                            }
                            checked={field.value}
                          ></Switch>
                        )}
                      </Field>
                      <div
                        className={
                          formik?.values?.isActive
                            ? "user-active"
                            : "user-disabled"
                        }
                      >
                        {formik?.values?.isActive ? "Active" : "Inactive"}
                      </div>
                    </div>
                  </div>
                )}
                {(formType === FORM_TYPE.ADD || formType === FORM_TYPE.EDIT) &&
                  !(initialFormValues?.isOrganizationActive === false) && (
                    <div className="d-flex justify-content-end">
                      <Button variant="contained" type="submit">
                        {formType === FORM_TYPE.ADD ? "Add" : "Save"}
                      </Button>
                    </div>
                  )}
                {errorMessage && (
                  <>
                    <div className="login-error-message p-4 mt-4">
                      <span className="error-message">{errorMessage} </span>
                    </div>
                  </>
                )}
              </form>
            )}
          </Formik>
        )}
      {formType === FORM_TYPE.VIEW && (
        <div className="d-flex justify-content-end">
          <Button
            type="button"
            variant="contained"
            onClick={() => {
              setFormType(FORM_TYPE.EDIT);
            }}
          >
            Edit
          </Button>
        </div>
      )}
    </div>
  );
};

export default AddUserHandler;
