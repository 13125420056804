import React, { useEffect, useState } from "react";
import "./Dropdown.scss";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { ReactComponent as DropdownIcon } from "../../../assets/svg/coolicon_down.svg";

function Dropdown(props: any) {
  const {
    label,
    menuItems,
    name,
    placeholder,
    dropdownSearchInputPlaceHolder,
    formik,
    required,
    disabled,
    searchable,
    errorMsg,
    onClearClick,
    isLoading,
    onChange,
  } = props;
  const [value, setValue] = useState(formik?.values[name] || "");
  const [dropDownValue, setDropDownValue] = useState(
    formik?.values[name] || ""
  );
  const [focused, setFocused] = useState<boolean>(false);
  useEffect(() => {
    if (props?.clearValue) {
      setValue(null);
      setDropDownValue(null);
    }
  }, [props?.clearValue]);

  return (
    <div className="input-group">
      {label && (
        <label>
          {label}
          {required && <span className="required">*</span>}
        </label>
      )}
      <div
        className={`input-dropdown-control ${disabled && "dropdown-disabled"} ${
          errorMsg && "error"
        }`}
      >
        {searchable ? (
          <Autocomplete
            options={menuItems}
            placeholder={dropdownSearchInputPlaceHolder}
            getOptionLabel={(option: any) => option.displayValue || ""}
            value={dropDownValue || ""}
            onInputChange={(event, newInputValue) => {
              props.onInputChange(event, newInputValue);
            }}
            onBlur={() => {
              formik?.setFieldTouched(name);
            }}
            onChange={(event, newValue) => {
              onChange && props?.onChange(newValue);
              setDropDownValue(newValue);
              if (newValue === null && onClearClick) {
                onClearClick();
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={dropdownSearchInputPlaceHolder}
                fullWidth
                value={dropDownValue}
                disabled={disabled}
                SelectProps={{
                  IconComponent: DropdownIcon,
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        ) : (
          <TextField
            select
            name={name}
            required={required}
            margin="normal"
            value={value}
            onChange={(value) => {
              if (props.onChange) {
                props?.onChange(value.target.value);
              }
              if (formik?.handleChange) {
                formik.handleChange(value);
              }
              setValue(value.target.value);
            }}
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={() => {
              setFocused(false);
            }}
            fullWidth
            disabled={disabled}
            SelectProps={{
              IconComponent: DropdownIcon,
            }}
            label={value || focused ? "" : placeholder}
          >
            {menuItems &&
              menuItems?.length > 0 &&
              menuItems?.map((menuItem: any) => (
                <MenuItem key={menuItem?.value} value={menuItem?.value}>
                  {menuItem?.displayValue}
                </MenuItem>
              ))}
          </TextField>
        )}
      </div>
      {errorMsg && (
        <div className="error-message">
          <span className="custom-error-message">{label} is required</span>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
