export const LOGIN_LITERALS = {
  support:
    "For support, please reach out to our support team at eagleadmin@precedencehealthcare.com",
  copyRights: "Copyright © 2024 Precedence Health Care Pty Ltd",
  somethingWentWrong: "Something went wrong",
  accessDenied: "Access Denied",
  passwordAlreadySet:
    "The password has been already set. Please log in with your credentials.",
  signIn: "Sign In",
  passwordSuccessfullySet: "Password has been set successfully",
  welcome: "Welcome",
  enterOtp: "Enter One Time Password (OTP)",
  resendOtp: "Resend OTP",
  validate: "Validate",
  otpInfo:
    "A one time password (a 6-digit number) has been sent to your registered email address. Please enter it below and click Validate. This code is valid for 15 minutes.",
  sessionExpired: "Session expired.",
  passwordUpdatedSuccessfully:
    "Password updated successfully. Please login with new credentials.",
  passwordUpdated: "Password updated successfully."
};

export const ErrorMessage = {
  ErrorAccountLocked:
    "Account locked due to invalid login attempts. Please try again after",
  ErrorInvalidUser: "Invalid username or password. Number of attempts left: ",
};
