import { useEffect, useState } from "react";
import Header from "../../components/shared/Widget/Header/Header";
import "./LandingLayout.scss";
import { Outlet } from "react-router-dom";
import { LOGIN_LITERALS } from "../../constants/literals";
import SideNav from "../../components/shared/Widget/Side-nav/Side-nav";
import { ConfigurationService } from "../../services/ConfigurationService";

function LandingLayout() {
  const [mobileToggle, setMobileToggle] = useState<boolean>(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState<boolean>(false);
  function mobileMenuToggle() {
    if (mobileToggle) {
      setMobileToggle(false);
    } else {
      setMobileToggle(true);
    }
  }

  return (
    <div
      className={
        "d-flex admin-content-wrapper " + (mobileToggle ? "show-menu" : "")
      }
    >
      {mobileToggle}
      <div
        className={"admin-left-wrapper " + (isSideNavOpen ? "" : "inactive")}
      >
        <div
          className="hamburger-icon pr-4 pt-4 text-right cursor-pointer"
          onClick={mobileMenuToggle}
        >
          <span className="nav-menu-close">{/* <Close /> */}</span>
        </div>
        <div>
          <div>{isSideNavOpen}</div>
          <SideNav
            isSideNavOpen={isSideNavOpen}
            setIsSideNavOpen={setIsSideNavOpen}
          />
        </div>
      </div>
      {
        <Header
          userName="superadmin@techigai.io"
          role="Support user"
          setIsSideNavOpen={(e) => {
            setIsSideNavOpen(e);
          }}
          isActive={isSideNavOpen}
        />
      }
      <div
        className={"admin-right-wrapper " + (isSideNavOpen ? "" : "inactive")}
      >
        <div
          onClick={mobileMenuToggle}
          className="nav-menu-open header-badge-wrapper"
        >
          <span className="material-icons nav-menu-open-icon">
            {/* <Menu /> */}
          </span>
        </div>
        <div className="admin-main-content p-4 h-100">
          {/* <Dashboard /> */}
          <div className="admin-main-wrapper">
            <Outlet />
          </div>
          <div className="support">
            <div className="copy-rights-wrapper d-flex justify-content-between">
              <div className="mb-3 copy-rights-content">
                {ConfigurationService.getCopyRightText()}
              </div>
              <div className="privacy-policy-wrapper">
                <span
                  className="link"
                  onClick={() => {
                    window.open(process.env.REACT_APP_RIBBON_TERMS_URL);
                  }}
                >
                  Terms of Use
                </span>{" "}
                |{" "}
                <span
                  className="link"
                  onClick={() => {
                    window.open(process.env.REACT_APP_RIBBON_PRIVACY_URL);
                  }}
                >
                  Privacy Policy
                </span>
                {" | "}
                <a
                  href={`mailto:${ConfigurationService.getSupportEmail()}`}
                  className="link"
                >
                  Support
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingLayout;
