import baseApi from "./baseApiService";
import { API_CONSTANTS } from "../../constants/apiConstants";

export const userApprovalApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsersForApproval: builder.query({
      query: (filterCriteria) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_ALL_USERS_FOR_APPROVAL}`,
        method: "POST",
        body: JSON.stringify(filterCriteria),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    rejectUser: builder.query({
      query: (rejectRequest) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.REJECT_USER}`,
        method: "POST",
        body: JSON.stringify(rejectRequest),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    approveUser: builder.query({
      query: (approveUserRequest) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.APPROVE_USER}`,
        method: "POST",
        body: JSON.stringify(approveUserRequest),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
  }),
});
export const {
  useLazyGetAllUsersForApprovalQuery,
  useLazyRejectUserQuery,
  useLazyApproveUserQuery,
} = userApprovalApi;
