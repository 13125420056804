export const siteConfiguration_LABLES = {
    brandName: "Brand Name",
    supportEmail: "Support Email Address",
    fromEmail: "From Email Address",
    siteURL: "User Management URL",
    userManagementLogoURL: "User Management Logo",
    extensionLogoURL: "Logo For Extension",
    extensionLogoSmallURL: "Logo For Extension (Small)",
    copyright: "Copyright", 
    colorPrimary: "Color (Primary)",
    colorSecondary: "Color (Secondary)"
};

export const siteConfiguration_Placeholders = {
    brandName: "Enter Name",
    supportEmail: "Enter Support Email Address",
    fromEmail: "Enter From Email Address",
    siteURL: "User Management URL",
    userManagementLogoURL: "User Management Logo - URL",
    extensionLogoURL: "Logo For Extension - URL",
    extensionLogoSmallURL: "Logo For Extension (Small) - URL",
    copyright: "Copyright Text", 
    colorPrimary: "Enter HEX code",
    colorSecondary: "Color (Secondary)"
}
  