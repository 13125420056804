export const dataStore = {
  access_token: "access_token",
  refresh_token: "refresh_token",
  login_code: "login_code",
  active_tab: "active_tab",
  theme_primary_color: "theme_primary_color",
  theme_secondary_color: "theme_secondary_color",
  copyright: "copyright",
  brand_um_logo: "brand_um_logo",
  brand_name: "brand_name",
  support_email: "support_email",
};
