function Download(props: any){
    return (
        <svg width={props.width || "24"} height={props.height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.42847 15.4286L3.42847 18.8572C3.42847 19.3118 3.60908 19.7479 3.93057 20.0693C4.25206 20.3908 4.6881 20.5714 5.14275 20.5714L18.857 20.5714C19.3117 20.5714 19.7477 20.3908 20.0692 20.0693C20.3907 19.7479 20.5713 19.3118 20.5713 18.8572V15.4286" stroke={props.fillColor || "var(--primary-color)"} stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
            <path d="M12 2.57141L12 15.4286" stroke={props.fillColor || "var(--primary-color)"} stroke-width="2.05714" stroke-miterlimit="10"/>
            <path d="M7.71436 11.1428L12.0001 15.4285L16.2858 11.1428" stroke={props.fillColor || "var(--primary-color)"} stroke-width="2.05714" stroke-miterlimit="10" stroke-linecap="square"/>
        </svg>
    );
}

export default Download;