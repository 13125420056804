import React from "react";
import "./Loader.scss";

function Loader(props: any) {
  return (
    <>
      {props?.isLoading && (
        <div className="loader">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="-466.4 259.6 280.2 47.3"
            enableBackground="new -466.4 259.6 280.2 47.3"
            xmlSpace="preserve"
          >
            <polyline
              className="animation"
              points="-373.9,281 -360.2,281 -355.2,263 -350.2,303 -340.2,273 -335.2,291 -329.2,281 -318,281 "
            ></polyline>
          </svg>
        </div>
      )}
    </>
  );
}

export default Loader;
