import { Navigate, Route, Routes } from "react-router-dom";
import LandingLayout from "../pages/LandingLayout/LandingLayout";
import UserManagement from "../components/UserManagement/UserManagement";
import SiteConfiguration from "../components/Configuration/SiteConfiguration";
import AuthenticationLayout from "../pages/AuthenticationLayout/AuthenticationLayout";
import SetPassword from "../components/authentication/SetPassword";
import { routePaths } from "../constants/routePaths";
import Login from "../components/authentication/Login";
import { useAuth } from "../context/AuthProvider";
import OtpValidation from "../components/authentication/OtpValidation";
import UpdatePassword from "../components/authentication/UpdatePassword";
import PageNotFound from "../pages/ErroPages/PageNotFound";
import SessionExpired from "../pages/ErroPages/SessionExpired";
import Dashboard from "../components/Dashboard/Dashboard";
import { UserService } from "../services/UserService";
import { Roles } from "../constants/data/Roles";

const RoutesConfig = () => {
  const auth = useAuth();

  return (
    <Routes>
      <Route element={<AuthenticationLayout />}>
        <Route
          path={`${routePaths.setPassword}`}
          element={<SetPassword isReset={false} />}
        />
        <Route
          path={`${routePaths.resetPassword}`}
          element={<SetPassword isReset={true} />}
        />
      </Route>
      {auth?.isLoggedIn ? (
        <Route element={<LandingLayout />}>
          <Route
            path={routePaths.dashboard}
            element={
              UserService.getClaims?.role === Roles.supportUser ? (
                <Dashboard />
              ) : (
                <Navigate to={routePaths.userManagement} />
              )
            }
          ></Route>
          <Route
            path={routePaths.siteConfiguration}
            element={
              UserService.getClaims?.role === Roles.supportUser ? (
                <SiteConfiguration />
              ) : (
                <Navigate to={routePaths.userManagement} />
              )
            }
          ></Route>
          <Route
            path={routePaths.userManagement}
            element={<UserManagement />}
          />
          <Route
            path={routePaths.updatePassword}
            element={<UpdatePassword />}
          />
          <Route path={routePaths.base} 
            element={
                <Navigate to={routePaths.userManagement} />
            } />
          <Route path={routePaths.login} element={<Navigate to={routePaths.userManagement} />} />
        </Route>
      ) : (
        <Route element={<AuthenticationLayout />}>
          <Route path={routePaths.otp} element={<OtpValidation />} />
          <Route path={routePaths.login} element={<Login />} />
          <Route path={routePaths.base} element={<Login />} />
          <Route
            path={routePaths.wild}
            element={<Navigate to={routePaths.login} />}
          />
          <Route path={routePaths.sessionExpiry} element={<SessionExpired />} />
        </Route>
      )}
      <Route element={<PageNotFound />} path={routePaths.wild}></Route>
    </Routes>
  );
};

export default RoutesConfig;
