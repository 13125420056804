import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { errorMessages } from "../../../constants/errorMessages";
import Input from "../../shared/Input/Input";
import { userManagement_LABELS } from "../../../constants/labels/userManagement/userManagement";
import { Button, Switch } from "@mui/material";
import "./OrganizationHandler.scss";
import AccordionComponent from "../../shared/Accordion/Accordion";
import { organization_LABELS } from "../../../constants/labels/organization/organization";
import { regex } from "../../../constants/regex";
import { ReactComponent as AddUser } from "../../../assets/svg/add_user_icon.svg";
import {
  useLazyAddNpisQuery,
  useLazyGetOrganizationByOrganizationIdQuery,
  useLazyToggleOrganizationStatusQuery,
} from "../../../services/rtkQueryServices/OrganizationApi";
import { Roles } from "../../../constants/data/Roles";
import { UserService } from "../../../services/UserService";
import { Utility } from "../../../utils/Utility";
function OrganizationHandler(Props: any) {
  const [organizationStatus, setOrganizationStatus] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>();
  const [practitionersData, setPractitionersData] = useState([]);
  const [practiceManagersData, setPracticeManagersData] = useState([]);
  const [setOrgStatus, orgStatus] = useLazyToggleOrganizationStatusQuery();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [organizationHandlerDetails, setOrganizationHandlerDetails] =
    useState<any>();
  const [setOrganizationDetails, organizationDetails] =
    useLazyGetOrganizationByOrganizationIdQuery();
  const [setAddNpis, addNpis] = useLazyAddNpisQuery();
  useEffect(() => {
    setOrganizationHandlerDetails(Props?.organizationDetails);
  }, [Props?.organizationDetails]);
  const { formRef } = Props;

  useEffect(() => {
    if (organizationHandlerDetails) {
      const organizationInitialValues: any = {
        npis: organizationHandlerDetails?.npis?.join(", "),
        organizationName: organizationHandlerDetails?.organizationName,
        addNpis: "",
      };
      formRef.current?.resetForm();
      setInitialValues(organizationInitialValues);
      formRef.current?.setFieldValue("addNpis", "");
      setPracticeManagersData(
        organizationHandlerDetails?.userDetails?.filter(
          (x: any) => x.role.toUpperCase() === Roles.practiceManager
        )
      );
      setPractitionersData(
        organizationHandlerDetails?.userDetails?.filter(
          (x: any) => x.role.toUpperCase() === Roles.practitioner
        )
      );
      setOrganizationStatus(organizationHandlerDetails?.isOrganizationActive);
    }
  }, [organizationHandlerDetails]);

  useEffect(() => {
    if (
      (orgStatus.isSuccess && !orgStatus.isFetching) ||
      (addNpis.isSuccess && !orgStatus.isFetching)
    ) {
      setOrganizationDetails(organizationHandlerDetails?.organizationId);
    } else if (orgStatus.isError || addNpis.isError) {
      setErrorMessage(
        (orgStatus.error as any)?.data?.message ||
          (addNpis.error as any)?.data?.message
      );
    }
  }, [orgStatus, addNpis]);

  useEffect(() => {
    if (organizationDetails.isSuccess) {
      setOrganizationHandlerDetails(organizationDetails?.data?.data);
    } else if (organizationDetails.isError) {
      setErrorMessage((organizationDetails.error as any).data.message);
    }
  }, [organizationDetails]);

  const validationSchema = Yup.object().shape({
    addNpis: Yup.string().matches(
      regex.commaSeparatedNpi,
      errorMessages.npiRegexMisMatch
    ),
  });

  const practitionersContent = () => {
    return (
      <>
        {practitionersData && practitionersData?.length > 0 ? (
          <div className="accordion-content-overflow">
            <div className="d-flex over-flow-auto flex-wrap flex-column">
              {practitionersData?.map((item: any) => (
                <div className="d-flex justify-content-between w-100">
                  <div
                    className="user-name-wrapper"
                    title={`${item?.firstName} ${item?.lastName}`}
                  >
                    {Utility.truncate(
                      `${item?.firstName} ${item?.lastName}`,
                      100
                    )}
                  </div>
                  <div
                    title={item?.isActive ? "Active" : "Inactive"}
                    className={item?.isActive ? "org-active" : "org-disabled"}
                  >
                    {item?.isActive ? "Active" : "Inactive"}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="flex-auto">{organization_LABELS?.noDataFound}</div>
          </div>
        )}
      </>
    );
  };

  const practiceManagersContent = () => {
    return (
      <>
        {practiceManagersData && practiceManagersData?.length > 0 ? (
          <div className="accordion-content-overflow">
            <div className="d-flex over-flow-auto  flex-wrap flex-column">
              {practiceManagersData?.map((item: any) => (
                <div className="d-flex justify-content-between w-100">
                  <div
                    className="user-name-wrapper w-80"
                    title={`${item?.firstName} ${item?.lastName}`}
                  >
                    {Utility.truncate(
                      `${item?.firstName} ${item?.lastName}`,
                      100
                    )}
                  </div>
                  <div
                    title={item?.isActive ? "Active" : "Inactive"}
                    className={item?.isActive ? "org-active" : "org-disabled"}
                  >
                    {item?.isActive ? "Active" : "Inactive"}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="flex-auto">{organization_LABELS?.noDataFound}</div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="organization-wrapper">
      <Input
        required
        name="npis"
        placeholder={userManagement_LABELS.npi}
        label={userManagement_LABELS.npi}
        value={organizationHandlerDetails?.npis?.join(", ")}
        disabled
        isFormElement={false}
      />
      <Input
        name="organizationName"
        placeholder={userManagement_LABELS.name}
        label={userManagement_LABELS.name}
        value={organizationHandlerDetails?.organizationName}
        required
        isFormElement={false}
        disabled
      />
      {initialValues && (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values: any) => {
            setErrorMessage("");
            setAddNpis({
              organizationId: organizationHandlerDetails?.organizationId,
              organizationNpis: values.addNpis.split(", "),
            });
          }}
        >
          {(formik) => (
            <form
              onKeyDown={Utility.preventSubmitOnEnter}
              onSubmit={formik.handleSubmit}
              className="d-flex flex-column"
            >
              <div className="add-npis-wrapper">
                <Input
                  name="addNpis"
                  placeholder={"Comma separated values (1234567890, 123456...)"}
                  label={userManagement_LABELS.addNpis}
                  onInput={(e) => {
                    formik.handleChange(e);
                  }}
                  errorMsg={
                    (formik.touched.addNpis || formik.submitCount > 0) &&
                    formik.errors.addNpis &&
                    (formik.errors as any).addNpis
                  }
                  value={formik?.values?.addNpis}
                  as={"textarea"}
                  disabled={!organizationStatus}
                  onKeyDown={Utility.allowOnlyNumericsAndCommas}
                />
              </div>
              <div className="mt-4 d-flex justify-content-end">
                <Button
                  type="submit"
                  variant="contained"
                  className={organizationStatus ? "disable-button" : "disable-button"}
                  disabled={
                    !organizationStatus || formik.values.addNpis.length === 0
                  }
                >
                  Add NPI
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
      <div className="ribbon-um-border-line"></div>
      {UserService.getClaims?.role?.toUpperCase() === Roles.supportUser && (
        <div
          className={
            "organization d-flex align-items-center justify-content-between mt-4 "
          }
        >
          <div className="organization-status-label">Organization status</div>
          <Switch
            checked={organizationStatus}
            onChange={(e) => {
              setOrgStatus(organizationHandlerDetails?.organizationId);
            }}
            onClick={(e: { stopPropagation: () => void }) => {
              e.stopPropagation();
            }}
            disabled={
              UserService.getClaims?.role?.toUpperCase() !== Roles.supportUser
            }
          />
        </div>
      )}
      <div className="mt-3">
        <div className="mb-4">
          <AccordionComponent
            sectionHeading={
              <div className="info-title d-flex justify-content-between w-100 align-items-center">
                <div>{organization_LABELS?.practiceManagers}</div>
                <div
                  className={
                    "d-flex flex-column align-items-center add-user " +
                    (organizationStatus ? "cursor-pointer" : "disabled")
                  }
                  title={organizationStatus ? "Add Practice manager" : ""}
                  onClick={(e) => {
                    if (organizationStatus) {
                      Props?.onClose();
                      Props?.openSlider();
                      if (Props?.setRole) Props?.setRole(Roles.practiceManager);
                    }
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <AddUser />
                  <div>Add User</div>
                </div>
              </div>
            }
            accordionBody={practiceManagersContent()}
          />
        </div>
        <div>
          <AccordionComponent
            sectionHeading={
              <div className="info-title d-flex justify-content-between w-100 align-items-center">
                <div>{organization_LABELS?.practitioners}</div>
                <div
                  className={
                    "d-flex flex-column align-items-center add-user " +
                    (organizationStatus ? "cursor-pointer" : "disabled")
                  }
                  title={organizationStatus ? "Add Practitioner" : ""}
                  onClick={(e) => {
                    if (organizationStatus) {
                      Props?.onClose();
                      Props?.openSlider();
                      if (Props?.setRole) Props?.setRole(Roles.practitioner);
                    }
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <AddUser />
                  <div>Add User</div>
                </div>
              </div>
            }
            accordionBody={practitionersContent()}
          />
        </div>
      </div>
      {errorMessage && (
        <>
          <div className="login-error-message p-4 mt-4">
            <span className="error-message">{errorMessage} </span>
          </div>
        </>
      )}
    </div>
  );
}

export default OrganizationHandler;
