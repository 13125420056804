import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import Modal from "../../shared/Modal/Modal";
import * as Yup from "yup";
import { errorMessages } from "../../../constants/errorMessages";
import Input from "../../shared/Input/Input";
import { userManagement_LABELS } from "../../../constants/labels/userManagement/userManagement";
import Dropdown from "../../shared/DropDown/Dropdown";
import Button from "@mui/material/Button";
import { regex } from "../../../constants/regex";
import {
  useLazyApproveUserQuery,
  useLazyRejectUserQuery,
} from "../../../services/rtkQueryServices/UserApprovalApi";
import { useGetAllRolesQuery } from "../../../services/rtkQueryServices/authenticationService";
import UtcDateTime from "../../shared/UtcDateTime/UtcDateTime";
import ConfirmationDialog from "../../shared/ConfirmationDialog/ConfirmationDialog";
import { confirmationMessages } from "../../../constants/confirmationMessages";
import { Roles } from "../../../constants/data/Roles";
import { Utility } from "../../../utils/Utility";
import { validationMessages } from "../../../constants/forms/validationMessages";

function UserApprovalHandler(props: any) {
  const {
    userApprovalProps,
    modalId,
    setModalId,
    rejectState,
    isRejected,
    setRejectState,
    setIsRejected,
  } = props;
  const [initialFormValue, setInitialFormValue] = useState<any>({});
  const [isOrganizationDisabled, setIsOrganizationDisabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [setRejectUser, rejectUser] = useLazyRejectUserQuery();
  const [setUserApproval, userApproval] = useLazyApproveUserQuery();
  const roles = useGetAllRolesQuery(null);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(errorMessages.required("First Name"))
      .max(64, errorMessages.characterLimit("First Name", 64))
      .matches(
        regex.nameValidation,
        errorMessages.alphabetsAndSpaceValidation("First Name")
      ),
    lastName: Yup.string()
      .required(errorMessages.required("Last Name"))
      .max(64, errorMessages.characterLimit("Last Name", 64))
      .matches(
        regex.nameValidation,
        errorMessages.alphabetsAndSpaceValidation("First Name")
      ),
    emailAddress: Yup.string()
      .required(errorMessages.required("Email Address"))
      .matches(regex.emailValidation, errorMessages.invalidEmail)
      .max(128, errorMessages.characterLimit("Email Address", 128)),
    contactNumber: Yup.string().max(
      28,
      errorMessages.characterLimit("Contact Number", 28)
    ),
    npi: Yup.string()
      .required(errorMessages.required("NPI"))
      .matches(regex.commaSeparatedNpi, errorMessages.npiRegexMisMatch),
    organization: Yup.string().max(
      100,
      errorMessages.characterLimit("Organization", 100)
    ),
    address: Yup.string().max(
      250,
      errorMessages.characterLimit("Address", 250)
    ),
    note:
      rejectState || isRejected
        ? Yup.string().required(errorMessages.required("Note")).max(1024)
        : Yup.string().max(1024),
  });

  useEffect(() => {
    if (userApproval.isSuccess || rejectUser.isSuccess) {
      setModalId("");
      setRejectState(false);
      setIsRejected(false);
      props?.onClose();
    }
  }, [rejectUser, userApproval]);
  useEffect(() => {
    if(isOrganizationDisabled)
    {
      setErrorMessage(validationMessages.userApproval);
    }
    else if(!isOrganizationDisabled && userApproval.error){
      setErrorMessage((userApproval.error as any)?.data?.message);
    }
  }, [isOrganizationDisabled, userApproval]);

  const menuItemRoles = useMemo(() => {
    let menuItems = roles.data?.data
      ?.filter(
        (item: any) => item?.roleName?.toUpperCase() !== Roles.supportUser
      )
      .map((item: any) => {
        return {
          value: item.roleId,
          displayValue: item.roleName,
        };
      });
    return menuItems;
  }, [roles]);

  const formRef = useRef<FormikProps<any>>(null);
  const [openConfirmationPopUp, setOpenConfirmationPopUp] =
    useState<boolean>(false);

  return (
    <>
      <ConfirmationDialog
        open={openConfirmationPopUp}
        message={confirmationMessages.changesWillNotBeSaved}
        onConfirm={() => {
          setModalId("");
          props?.onClose();
          setOpenConfirmationPopUp(false);
        }}
        onCancel={() => {
          setOpenConfirmationPopUp(false);
        }}
      />
      <Modal
        isOpen={
          modalId && userApprovalProps?.data?.applicationUserId === modalId
        }
        onClose={() => {
          if (formRef.current?.dirty) {
            setOpenConfirmationPopUp(true);
          } else {
            setModalId("");
            if (rejectState) {
              setRejectState(false);
            }
            if (isRejected) {
              setIsRejected(false);
            }
          }
          setErrorMessage("");
        }}
        title={
          isRejected ? "Rejected" : rejectState ? "Reject User" : "Approve User"
        }
        modalWidth="1000px"
        headerBg="#FFFFFF"
        headerColor="var(--primary-color)"
        modalHeaderBottomStyle={{
          borderBottom: "1px solid var(--primary-color)",
        }}
      >
        {userApprovalProps?.data?.applicationUserId === modalId && (
          <Formik
            initialValues={{
              firstName: userApprovalProps?.data?.firstName || "",
              lastName: userApprovalProps?.data?.lastName || "",
              emailAddress: userApprovalProps?.data?.emailAddress || "",
              contactNumber: userApprovalProps?.data?.contactNumber || "",
              npi: userApprovalProps?.data?.npis || "",
              organization: userApprovalProps?.data?.organizationName || "",
              address: userApprovalProps?.data?.organizationAddress || "",
              roleId: userApprovalProps?.data?.roleId || "",
              note: userApprovalProps?.data?.note || "",
            }}
            innerRef={formRef}
            validationSchema={validationSchema}
            onSubmit={(values: any) => {
              if(!userApprovalProps?.data?.isOrganizationActive)
              {
                setIsOrganizationDisabled(true);
              }
              if (rejectState) {
                setRejectUser({
                  emailAddress: values?.emailAddress,
                  note: values?.note,
                });
              } else if (!isRejected) {
                const approvalRequestModel = {
                  firstName: values?.firstName,
                  lastName: values?.lastName,
                  roleId: values?.roleId,
                  emailAddress: values?.emailAddress,
                  contactNumber: values?.contactNumber,
                  organizationId: userApprovalProps?.data?.organizationId || "",
                };
                setUserApproval(approvalRequestModel);
              }
            }}
          >
            {(formik) => (
              <form
                onKeyDown={Utility.preventSubmitOnEnter}
                className="d-flex flex-direction-column gap-1 "
                onSubmit={formik.handleSubmit}
              >
                <div className="d-flex flex-column p-4">
                  <div className="row mx-0">
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
                      <Input
                        name="firstName"
                        placeholder={userManagement_LABELS.firstName}
                        label={userManagement_LABELS.firstName}
                        errorMsg={
                          formik.touched.firstName &&
                          formik.errors.firstName &&
                          (formik.errors as any).firstName
                        }
                        value={formik.values.firstName}
                        onInput={(e) => {
                          formik.handleChange(e);
                        }}
                        disabled={isRejected || rejectState}
                        required
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
                      <Input
                        name="lastName"
                        placeholder={userManagement_LABELS.lastName}
                        label={userManagement_LABELS.lastName}
                        errorMsg={
                          formik.touched.lastName &&
                          formik.errors.lastName &&
                          (formik.errors as any).lastName
                        }
                        onInput={(e) => {
                          formik.handleChange(e);
                        }}
                        value={formik.values.lastName}
                        disabled={isRejected || rejectState}
                        required
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
                      <Input
                        name="emailAddress"
                        placeholder={userManagement_LABELS.emailAddress}
                        label={userManagement_LABELS.emailAddress}
                        onInput={(e) => {
                          formik.handleChange(e);
                        }}
                        errorMsg={
                          formik.touched.emailAddress &&
                          formik.errors.emailAddress &&
                          (formik.errors as any).emailAddress
                        }
                        value={formik.values.emailAddress}
                        disabled
                        required
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
                      <Input
                        name="contactNumber"
                        placeholder={userManagement_LABELS.contactNumber}
                        label={userManagement_LABELS.contactNumber}
                        onInput={(e) => {
                          formik.handleChange(e);
                        }}
                        errorMsg={
                          formik.touched.contactNumber &&
                          formik.errors.contactNumber &&
                          (formik.errors as any).contactNumber
                        }
                        value={formik.values.contactNumber}
                        disabled={isRejected || rejectState}
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
                      <Dropdown
                        menuItems={menuItemRoles}
                        name={"roleId"}
                        formik={formik}
                        label="Role"
                        required
                        placeholder="Role"
                        forceShowError={formik.submitCount > 0}
                        disabled={isRejected || rejectState}
                      />
                    </div>
                  </div>
                  <div className="my-3">
                    <div className="mb-3">
                      {userManagement_LABELS.organizationDetails}
                    </div>
                    <div className="row mx-0">
                      <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
                        <Input
                          name="npi"
                          placeholder={userManagement_LABELS.npi}
                          label={userManagement_LABELS.npi}
                          onInput={(e) => {
                            formik.handleChange(e);
                          }}
                          errorMsg={
                            formik.touched.npi &&
                            formik.errors.npi &&
                            (formik.errors as any).npi
                          }
                          value={formik.values.npi}
                          disabled
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
                        <Input
                          name="organization"
                          placeholder={userManagement_LABELS.organization}
                          label={userManagement_LABELS.organization}
                          onInput={(e) => {
                            formik.handleChange(e);
                          }}
                          errorMsg={
                            formik.touched.organization &&
                            formik.errors.organization &&
                            (formik.errors as any).organization
                          }
                          value={formik.values.organization}
                          disabled
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
                        <Input
                          name="address"
                          placeholder={userManagement_LABELS.address}
                          label={userManagement_LABELS.address}
                          onInput={(e) => {
                            formik.handleChange(e);
                          }}
                          errorMsg={
                            formik.touched.address &&
                            formik.errors.address &&
                            (formik.errors as any).address
                          }
                          value={formik.values.address}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  {(rejectState || isRejected) && (
                    <div className="reject-note-wrapper mb-3">
                      <Input
                        name="note"
                        placeholder={userManagement_LABELS.noteDescription}
                        label={userManagement_LABELS.addNote}
                        onInput={(e) => {
                          formik.handleChange(e);
                        }}
                        errorMsg={
                          formik.touched.note &&
                          formik.errors.note &&
                          (formik.errors as any).note
                        }
                        value={formik.values.note}
                        disabled={isRejected}
                        required
                        labelHelpText={"Will be communicated to user"}
                        as={"textarea"}
                      />
                    </div>
                  )}
                  {(errorMessage &&
                    <div className="error-message mb-4 ms-2">{errorMessage}</div>)}
                  <div className="footer d-flex justify-content-between mx-2">
                    {!isRejected ? (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          if (formRef.current?.dirty) {
                            setOpenConfirmationPopUp(true);
                          } else {
                            setModalId("");
                            if (rejectState) {
                              setRejectState(false);
                            }
                            if (isRejected) {
                              setIsRejected(false);
                            }
                          }
                          setErrorMessage("");
                        }}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <div>
                        <div className="rejected-on">Rejected On</div>
                        <div className="rejected-on-date">
                          <UtcDateTime
                            value={userApprovalProps?.data?.requestRaisedDate}
                          />
                        </div>
                      </div>
                    )}
                    {rejectState ? (
                      <Button variant="contained" type="submit">
                        Reject
                      </Button>
                    ) : isRejected ? (
                      <div className="align-items-center d-flex">
                        <Button
                          variant="contained"
                          type="button"
                          onClick={() => {
                            if (formRef.current?.dirty) {
                              setOpenConfirmationPopUp(true);
                            } else {
                              setModalId("");
                              if (rejectState) {
                                setRejectState(false);
                              }
                              if (isRejected) {
                                setIsRejected(false);
                              }
                            }
                          }}
                        >
                          Close
                        </Button>
                      </div>
                    ) : (
                      <Button variant="contained" type="submit">
                        Confirm
                      </Button>
                    )}
                  </div>                 
                </div>
              </form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
}

export default UserApprovalHandler;
