import React, { useRef, useState, useEffect } from "react";
import InitialAvatar from "../InitialAvatar/InitialAvatar";
import "./Header.scss";
import { ReactComponent as Logout } from "../../../../../src/assets/svg/logout.svg";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { StyledMenu } from "../Theme/StyledMenu";
import { useAuth } from "../../../../context/AuthProvider";
import { UserService } from "../../../../services/UserService";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../../../constants/routePaths";
import { USERTAB } from "../../../../enums/userTabs";
import { ReactComponent as HamBurger } from "../../../../../src/assets/svg/HamBurger.svg";
import { ReactComponent as OpenHamBurger } from "../../../../../src/assets/svg/OpenHamBurger.svg";
import { useLazySignOutQuery } from "../../../../services/rtkQueryServices/authenticationService";
import { Roles } from "../../../../constants/data/Roles";
import { ConfigurationService } from "../../../../services/ConfigurationService";
export interface HeaderProps {
  userName?: string;
  role?: string;
  profilePic?: string;
  isActive?: boolean;
  seenNotification?: () => void;
  onSearch?: (value: string) => void;
  setIsSideNavOpen?: (value: boolean) => void;
}

const Header = (props: HeaderProps) => {
  const { profilePic, isActive } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [headerShadow, setHeaderShadow] = useState<boolean>(false);
  const profileRef = useRef(null);
  const[imgSrc, setImageSrc] = useState<any>("");
  const [logOutQuery] = useLazySignOutQuery();
  useEffect(() => {
    window.addEventListener("scroll", scrollEvent);
    function scrollEvent(event: Event) {
      if (window.scrollY > 20) {
        setHeaderShadow(true);
      } else {
        setHeaderShadow(false);
      }
    }
    window.addEventListener("keydown", focusSearchInput);
    function focusSearchInput(event: KeyboardEvent): void {
      if (event.ctrlKey && event.code === "KeyF" && inputRef.current) {
        inputRef.current.focus();
      }
    }
    return () => {
      window.removeEventListener("keydown", focusSearchInput);
    };
  });
  useEffect(() => {
    setImageSrc(ConfigurationService.getLogoUrl());
  },[]);
  const [anchorEl, setAnchorEl] = useState<any>();
  const auth = useAuth();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = async () => {
    await logOutQuery({ userId: UserService.getClaims?.userId });
    auth?.logOut();
  };

  const navigateHome = () => {
    UserService.userActiveTab = USERTAB.USERMANAGEMENTTAB;
    window.location.replace("/");
  };

  return (
    <div className="header-wrapper">
      <header className={"main-header " + (headerShadow && "header-shadow")}>
        {UserService.getClaims?.role === Roles.supportUser &&
          (isActive ? (
            <OpenHamBurger
              onClick={() => {
                if (props.setIsSideNavOpen) props.setIsSideNavOpen(!isActive);
              }}
              className="cursor-pointer"
            />
          ) : (
            <HamBurger
              onClick={() => {
                if (props.setIsSideNavOpen) props.setIsSideNavOpen(!isActive);
              }}
              className="cursor-pointer"
            />
          ))}
        <div
          onClick={() => navigateHome()}
          className="cursor-pointer ribbon-user-management-logo-wrapper ms-3"
        >
        <img src={imgSrc} />
        </div>
        <div className="actions">
          <div className="account-action">
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="text"
              disableElevation
              onClick={handleClick}
              className="h-100"
            >
              <div className="user-profile" ref={profileRef}>
                <div className="user-profile-control">
                  <InitialAvatar
                    name={UserService?.getClaims?.userName}
                    avatar={profilePic}
                    bgColor={"var(--primary-color)"}
                  ></InitialAvatar>
                  <div className="d-flex flex-column">
                    <span
                      className="user-name"
                      title={UserService?.getClaims?.userName}
                    >
                      {UserService?.getClaims?.userName}
                      <svg
                        width="10"
                        height="7"
                        viewBox="0 0 10 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ms-3"
                      >
                        <path
                          d="M8.21973 0.969727L4.99998 4.18948L1.78023 0.969727L0.719727 2.03023L4.99998 6.31048L9.28023 2.03023L8.21973 0.969727Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="user-name role">
                      {UserService.getClaims?.role}
                    </span>
                  </div>
                </div>
              </div>
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  navigate(routePaths.updatePassword);
                  handleClose();
                }}
                disableRipple
              >
                <div className="d-flex justify-content-between align-items-center w-100 mx-3 profile-wrapper change-password-wrapper">
                  <span>Change password</span>
                </div>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logOut();
                }}
                disableRipple
              >
                <div className="d-flex justify-content-between align-items-center w-100 mx-3 profile-wrapper logout-wrapper">
                  <span>Sign out</span>
                  <Logout />
                </div>
              </MenuItem>
            </StyledMenu>
          </div>
        </div>
      </header>
    </div>
  );
};
export default Header;
