import { KEYCODE } from "../enums/keyCodes";

export class Utility {
  /**
   * Convert any date time to Date/Month/Year.
   * @returns Converted any date time to Date/Month/Year.
   */
  static getDateInParticularFormat(dateValue: string) {
    const dateObj = new Date(dateValue);
    // Extract the date, time, and month using various Date methods
    const date = dateObj.getDate().toLocaleString("en-us", {
      minimumIntegerDigits: 2,
    });
    const month = (dateObj.getMonth() + 1).toLocaleString("en-us", {
      minimumIntegerDigits: 2,
    });
    const year = dateObj.getFullYear();
    // Construct the final date, time, and month strings in the desired format
    let formattedDate = `${year}-${month}-${date}`;
    return formattedDate;
  }

  static getTime(dateValue: string, getIn24HoursFormat: boolean = false) {
    const dateObj = new Date(dateValue);
    const hoursIn24Format = dateObj.getHours().toLocaleString("en-us", {
      minimumIntegerDigits: 2,
    });
    const hoursIn12Format = (Number(hoursIn24Format) % 12 || 12).toLocaleString(
      "en-us",
      {
        minimumIntegerDigits: 2,
      }
    );
    const minutes = dateObj.getMinutes().toLocaleString("en-us", {
      minimumIntegerDigits: 2,
    });
    const seconds = dateObj.getSeconds().toLocaleString("en-us", {
      minimumIntegerDigits: 2,
    });
    const timeIn12HoursFormat = `${hoursIn12Format}:${minutes}:${seconds} ${Number(hoursIn24Format) >= 12 ? "PM" : "AM"
      }`;
    return getIn24HoursFormat
      ? `${hoursIn24Format}:${minutes}`
      : timeIn12HoursFormat;
  }

  /**
   * Validates if the input value is only numbers and required characters(ex:Tab)
   * @returns boolean based on whether the input value has to be added to the input
   */
  static allowOnlyNumericsAndCommas = (e: any) => {
    if (
      !(
        (e.key.charCodeAt(0) >= KEYCODE.ZERO &&
          e.key.charCodeAt(0) <= KEYCODE.NINE) ||
        e.keyCode === KEYCODE.BACKSPACE ||
        e.keyCode === KEYCODE.TAB ||
        e.keyCode === KEYCODE.LEFTARROW ||
        e.keyCode === KEYCODE.RIGHTARROW ||
        e.keyCode === KEYCODE.DELETE ||
        e.keyCode === KEYCODE.ENTER ||
        e.keyCode === KEYCODE.COMMA ||
        e.keyCode === KEYCODE.SPACEBAR
      )
    ) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  /**
   * Validates if the input value is only numbers and required characters(ex:Tab)
   * @returns boolean based on whether the input value has to be added to the input
   */
  static allowOnlyNumerics = (e: any) => {
    if (
      !(
        (e.key.charCodeAt(0) >= KEYCODE.ZERO &&
          e.key.charCodeAt(0) <= KEYCODE.NINE) ||
        e.keyCode === KEYCODE.BACKSPACE ||
        e.keyCode === KEYCODE.TAB ||
        e.keyCode === KEYCODE.LEFTARROW ||
        e.keyCode === KEYCODE.RIGHTARROW ||
        e.keyCode === KEYCODE.DELETE ||
        e.keyCode === KEYCODE.ENTER
      )
    ) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  static allowOnlyNumericsAndCtrlV = (e: any) => {
    if (
      !(
        (e.key.charCodeAt(0) >= KEYCODE.ZERO &&
          e.key.charCodeAt(0) <= KEYCODE.NINE) ||
        e.keyCode === KEYCODE.BACKSPACE ||
        e.keyCode === KEYCODE.TAB ||
        e.keyCode === KEYCODE.LEFTARROW ||
        e.keyCode === KEYCODE.RIGHTARROW ||
        e.keyCode === KEYCODE.DELETE ||
        e.keyCode === KEYCODE.ENTER ||
        ((e.ctrlKey || e.metaKey) && e.keyCode === KEYCODE.V)
      )
    ) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  /**
   * Convert seconds.
   * @returns Converted seconds to hours,minutes,seconds.
   */
  static getTimeRemaining(totalSecs: number) {
    var hours = Math.floor(totalSecs / (60 * 60));

    var divisor_for_minutes = totalSecs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    return {
      totalSecs,
      hours,
      minutes,
      seconds,
    };
  }

  /** 
   * Get UTC Date
  */
  static getCurrentUTCDate()  {
    var date = new Date();
    var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());
    return new Date(now_utc);
  }

  /**
   * Gets filename from Response headers
   * @param headers 
   * @returns 
   */
  static getFileNameFromResponseHeaders(headers: any) {
    let contentDisposition = headers?.get('Content-Disposition');
    var filename = "";
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) { 
          filename = matches[1].replace(/['"]/g, '');
        }
    }
    return filename;
  }

  static truncate(str: string, n: number) {
    return str.length > n ? str.slice(0, n) + "..." : str;
  }

  static preventSubmitOnEnter(keyEvent: any) {
    if (keyEvent.keyCode === 13) {
      keyEvent.preventDefault();
    }
  }
}
