import baseApi from "./baseApiService";
import { API_CONSTANTS } from "../../constants/apiConstants";

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: (filterCriteria) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_ALL_USERS}`,
        method: "POST",
        body: JSON.stringify(filterCriteria),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
  }),
});
export const { useLazyGetAllUsersQuery } = userApi;
