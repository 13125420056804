import React, { useState } from "react";
import "./LoginError.scss";
import { Link } from "react-router-dom";
import { useLazySetPasswordInitiationQuery } from "../../services/rtkQueryServices/authenticationService";
import { LOGIN_LITERALS } from "../../constants/literals";
import { ConfigurationService } from "../../services/ConfigurationService";

function LoginError(props: any) {
  const [initiateSetPassword] = useLazySetPasswordInitiationQuery();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [infoMessage, setInfoMessage] = useState<string>();
  return (
    <div className="d-flex w-100 flex-column login-error-wrapper">
      <div className="p-3 my-3 error-information">
        {props.errorMessage === "Invalid/Expired Link" ? (
          <div>
            {props.errorMessage + ". Please contact "}
            <a
              href={`mailto:${ConfigurationService.getSupportEmail()}`}
              className="link"
            >
              {" "}
              support
            </a>
          </div>
        ) : (
          infoMessage || errorMessage || props.errorMessage
        )}
      </div>
      <div className="support-contact-details">
        <div>
          {props?.isLinkExpired && !props?.isReset && !infoMessage ? (
            <div>
              <span
                className="link"
                onClick={() => {
                  initiateSetPassword(
                    encodeURIComponent(encodeURIComponent(props?.userId))
                  )
                    .then((res) => {
                      if (res?.data?.isSuccess) {
                        setInfoMessage(
                          "Set password link has been sent to your email address"
                        );
                      } else {
                        setErrorMessage((res.error as any).data.message);
                      }
                    })
                    .catch((e: any) => {
                      setErrorMessage(LOGIN_LITERALS.somethingWentWrong);
                    });
                }}
              >
                Click here{" "}
              </span>
              to receive the link again.
            </div>
          ) : (
            <>
              <Link to={"/login"}>Sign In</Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginError;
